import React from "react";
import { Layout } from "./Layout";
import { process } from "@progress/kendo-data-query";
import { DocumentDetailsGrid } from "./DocumentDetailsGrid";
import { DocumentModal } from "./DocumentModal";
import { MaterialSynoymsModal } from "./MaterialSynoymsModal";
import useFetchWithMsal from "../hooks/useFetchWithMsal";
import { useNavigate } from "react-router-dom";
import { SpheraSubTab, SpheraTab } from "@sphera-cloud/component-ui-reactjs";
import { Popover } from "@progress/kendo-react-tooltip";
import { apiRoutes } from "../apiRoutes";
import { cookieHandler } from "../cookieHandler";
import { DateFormater } from "./DateFormater";
import { ChangeDocumentSensitivityModal } from "./ChangeDocumentSensitivityModal";
import { DeleteDocumentsModal } from "./DeleteDocumentsModal";
import { Menu, MenuItem } from "@progress/kendo-react-layout";
import { Popup } from "@progress/kendo-react-popup";

export function DocumentDetails(props) {
    const phraseSectionInfo = 1;
    const phraseSectionIn = 3;
    const phraseSectionPh = 9;
    const phraseSectionTrans = 14;
    const { adjustForTimezone, formatDate } = DateFormater();
    const sensitivityLevels = [
        {
            sensitivity: props.localizations.InternalProprietary,
            sensitivityId: 1
        },
        {
            sensitivity: props.localizations.Public,
            sensitivityId: 2
        },
    ];

    const statusTypes = [
        {
            status: props.localizations.Inactive,
            statusId: 0
        },
        {
            status: props.localizations.Active,
            statusId: 1
        },
        {
            status: props.localizations.Pending,
            statusId: 2
        },
        {
            status: props.localizations.Failed,
            statusId: 3
        }
    ];
    const { setCookie, getCookie } = cookieHandler();

    let documentGridSettingsJSON = getCookie("SpheraDocumentFilterStatus");
    let statusTemp = false;
    if (documentGridSettingsJSON != null && documentGridSettingsJSON !== "") {
        statusTemp = documentGridSettingsJSON === "true";
    }

    const navigate = useNavigate();
    const hrefParts = window.location.href.split("/");
    const MaterialId = hrefParts[hrefParts.length - 1].replace("?fromSearch=true", "");
    const [materialData, setmaterialData] = React.useState({});
    const [success, setsuccess] = React.useState(false);
    const [error, seterror] = React.useState(false);
    const [checkAllChecked, setcheckAllChecked] = React.useState(false);
    const [errorMessage, seterrorMessage] = React.useState("");
    const [documentColumns, setdocumentColumns] = React.useState([]);
    const [materialColumns, setmaterialColumns] = React.useState([]);
    const [ColumnsOriginal, setColumnsOriginal] = React.useState([]);
    const [selectedDoc, setselectedDoc] = React.useState({ Ingredients: [] });

    const [hasManu, sethasManu] = React.useState(false);
    const [hasCas, sethasCas] = React.useState(false);
    const [casLoc, setcasLoc] = React.useState("");
    const [manuLoc, setmanuLoc] = React.useState("");
    const [nameLoc, setnameLoc] = React.useState("");

    const [standardIngredients, setstandardIngredients] = React.useState([]);
    const [standardDataState, setstandardDataState] = React.useState({ skip: 0, take: 25 });
    const [standardDataResult, setstandardDataResult] = React.useState(
        process([], { skip: 0, take: 25 })
    );
    const [fullIngredients, setfullIngredients] = React.useState([]);
    const [fullDataState, setfullDataState] = React.useState({ skip: 0, take: 25 });
    const [fullDataResult, setfullDataResult] = React.useState(
        process([], { skip: 0, take: 25 })
    );

    const [transportation, settransportation] = React.useState([]);
    const [transportationDataState, settransportationDataState] = React.useState({ skip: 0, take: 25 });
    const [transportationDataResult, settransportationDataResult] = React.useState(
        process([], { skip: 0, take: 25 })
    );

    const standardDataStateChange = (event) => {
        setstandardDataResult(process(standardIngredients, event.dataState));
        setstandardDataState(event.dataState);
    };
    const fullDataStateChange = (event) => {
        setfullDataResult(process(fullIngredients, event.dataState));
        setfullDataState(event.dataState);
    };

    const transportationDataStateChange = (event) => {
        setfullDataResult(process(fullIngredients, event.dataState));
        setfullDataState(event.dataState);
    };

    const [phraseSections, setphraseSections] = React.useState([]);
    const [phraseSectionsSelected, setphraseSectionsSelected] = React.useState([]);
    const [sectionsDropdownData, setsectionsDropdownData] = React.useState([]);

    const [originalDocuments, setoriginalDocuments] = React.useState([]);
    const [documents, setdocuments] = React.useState([]);
    const [dataState, setDataState] = React.useState({ skip: 0, take: 25 });
    const [pending, setPending] = React.useState(false);
    const [modalPending, setmodalPending] = React.useState(false);
    const [documentLink, setDocumentLink] = React.useState("");
    const [changeSensitivityValue, setchangeSensitivityValue] = React.useState("1");
    const [showSynsModal, setshowSynsModal] = React.useState(false);
    const [showSensitivityModal, setshowSensitivityModal] = React.useState(false);
    const [showDeleteDocumentsModal, setshowDeleteDocumentsModal] = React.useState(false);
    const [synonyms, setsynonyms] = React.useState([]);
    const [sectionsTree, setsectionsTree] = React.useState("");
    const showSynonymsModal = () => { setshowSynsModal(!showSynsModal); }
    const toggleSensitivity = () => {
        let selected = documents.filter(x => x.selected);

        if (selected.length > 0)
            setshowSensitivityModal(!showSensitivityModal);
    }

    const toggleDeleteDocuments = () => {
        let selected = documents.filter(x => x.selected);

        if (selected.length > 0)
            setshowDeleteDocumentsModal(!showDeleteDocumentsModal);
    }

    const offSet = React.useRef({
        left: 0,
        top: 0,
    });
    const [show, setShow] = React.useState(false);
    const handleContextMenu = (e) => {
        e.preventDefault();
        offSet.current = {
            left: e.clientX,
            top: e.clientY,
        };
        setShow(true);
    };

    const documentsMenuClick = (e) => {
        if (e.item.text === 'Delete Documents')
            toggleDeleteDocuments();
        else
            toggleSensitivity();
    }

    const allSynsLink = <a className="gridLink" onClick={showSynonymsModal} onKeyDown={(e) => { if (e.code === "Enter") showSynonymsModal(); }}>{props.localizations.ShowAll}</a>;
    const [dataResult, setDataResult] = React.useState(
        process(documents, dataState)
    );
    const dataStateChange = (event) => {
        setDataResult(process(documents, event.dataState));
        setDataState(event.dataState);
    };

    const backToSearchClick = () => {
        const urlParams = new URLSearchParams(window.location.search);
        let searchPhrase = urlParams.get('fromSearch');
        if (searchPhrase === "true")
            navigate("/?search=" + localStorage.getItem("sphdsSearchPhrase"));
        else
            navigate(-1);
    };

    const { execute, result } = useFetchWithMsal({
        scopes: props.loginConfig.protectedResources.apiDocumentList.scopes.read,
    });

    const [showModal, setshowModal] = React.useState(false);

    const base64ToArrayBuffer = (base64) => {
        var binaryString = window.atob(base64);
        var binaryLen = binaryString.length;
        var bytes = new Uint8Array(binaryLen);
        for (var i = 0; i < binaryLen; i++) {
            var ascii = binaryString.charCodeAt(i);
            bytes[i] = ascii;
        }
        return bytes;
    };

    const checkboxChange = (documentId, checked) => {
        let newDocuments = documents;
        newDocuments.find(x => x.documentId === documentId).selected = checked;

        setoriginalDocuments(newDocuments);
        setdocuments(newDocuments);
        setDataResult(process(newDocuments, dataState));
    };

    const checkAllChange = (documentId, checked) => {
        let newDocuments = documents;
        newDocuments.forEach(x => x.selected = checked && x.status);

        setoriginalDocuments(newDocuments);
        setdocuments(newDocuments);
        setDataResult(process(newDocuments, dataState));
        setcheckAllChecked(checked);
    };

    const submitChangeDocumentSensitivity = () => {
        let DocumentIds = documents.filter(x => x.selected).map(y => y.documentSourceId);

        setmodalPending(true);
        let postData = {
            sensitivity: changeSensitivityValue,
            materialId: MaterialId,
            DocumentSourceIds: DocumentIds
        };

        execute("PATCH", apiRoutes.documents.patch, postData)
            .then((json) => {
                if (json != null) {
                    setmodalPending(false);
                    setshowSensitivityModal(false);
                    setcheckAllChecked(false);
                    setsuccess(true);
                    setTimeout(function () {
                        getData();
                        setsuccess(false);
                    }, 5000);
                }
            })
            .catch((err) => {
                seterrorMessage(err);
                seterror(true);
            });
    }

    const submitDeleteDocuments = () => {
        let DocumentIds = documents.filter(x => x.selected).map(y => y.documentId);

        setmodalPending(true);
        let postData = DocumentIds.join(",");

        execute("POST", apiRoutes.documents.deleteDoc, postData)
            .then((json) => {
                if (json != null) {
                    setmodalPending(false);
                    setshowDeleteDocumentsModal(false);
                    setcheckAllChecked(false);
                    setsuccess(true);
                    setTimeout(function () {
                        getData();
                        setsuccess(false);
                    }, 5000);
                }
            })
            .catch((err) => {
                seterrorMessage(err);
                seterror(true);
            });
    }

    const listToTree = (list) => {
        var map = {}, node, roots = [], i;

        for (i = 0; i < list.length; i += 1) {
            map[list[i].phraseSectionTypeId] = i;
            list[i].children = [];
        }

        for (i = 0; i < list.length; i += 1) {
            node = list[i];
            if (node.phraseSectionType.parentPhraseSectionTypeId !== null) {
                list[map[node.phraseSectionType.parentPhraseSectionTypeId]].children.push(node);
            } else {
                roots.push(node);
            }
        }
        return roots;
    }

    const toggle = (documentName) => {
        if (documentName.indexOf == null || documentName.indexOf(".pdf") > 0)
            setshowModal(!showModal);

        if (!showModal) {
            let docId = documentName.replace(".pdf", "");
            let document = materialData.documents.find(x => x.documentId === docId);
            let sections = [];
            if (document) {
                let sectionAdded = false;
                document.documentPhrases.forEach(x => {
                    let section = phraseSections.find(y => y.phraseSectionId === x.phraseSectionId);
                    let newSection = sections.find(y => y.phraseSectionId === section.phraseSectionId);
                    if (section && !newSection) {
                        section.phrases = document.documentPhrases.filter(x => x.phraseSectionId === section.phraseSectionId);
                        section.value = section.phraseSectionId;
                        section.label = section.phraseSectionType.name;
                        section.show = true;
                        sections.push(section);
                    }
                })
                do {
                    sectionAdded = false;
                    for (var i = 0; i < sections.length;i++){
                        if (sections[i].phraseSectionType.parentPhraseSectionTypeId) {
                            let section = sections.find(y => y.phraseSectionTypeId === sections[i].phraseSectionType.parentPhraseSectionTypeId );
                            if (!section) {
                                let newSection = phraseSections.find(y => y.phraseSectionTypeId === sections[i].phraseSectionType.parentPhraseSectionTypeId);
                                newSection.phrases = document.documentPhrases.filter(x => x.phraseSectionId === newSection.phraseSectionId);
                                newSection.value = newSection.phraseSectionId;
                                newSection.label = newSection.phraseSectionType.name;
                                newSection.show = true;
                                sections.push(newSection);
                                sectionAdded = true;
                            }
                        }
                    }
                }
                while (sectionAdded)
                let tree = listToTree(sections.sort((x, y) => x.sortOrder < y.sortOrder));

                let standardIngredients = [];
                let fullIngredients = [];

                if (document.formulations.length > 0) {
                    if (document.formulations[0].formulationType === 0)
                        standardIngredients = document.formulations[0].ingredients;
                    else
                        fullIngredients = document.formulations[0].ingredients;
                }
                if (document.formulations.length > 1) {
                    if (document.formulations[1].formulationType === 0)
                        standardIngredients = document.formulations[1].ingredients;
                    else
                        fullIngredients = document.formulations[1].ingredients;
                }

                setstandardIngredients(standardIngredients);
                setfullIngredients(fullIngredients);
                setstandardDataResult(process(standardIngredients, { skip: 0, take: 25 }));
                setstandardDataState({ skip: 0, take: 25 });
                setfullDataResult(process(fullIngredients, { skip: 0, take: 25 }));
                setfullDataState({ skip: 0, take: 25 });

                document.transportation.forEach(x => {
                    x.placards = "";
                    x.hazards.forEach(y => {
                        x.placards += " " + y.class + ",";
                    });

                    x.placards = x.placards.substring(0, x.placards.length - 1);
                });

                settransportation(document.transportation);
                settransportationDataResult(process(document.transportation, { skip: 0, take: 25 }));
                settransportationDataState({ skip: 0, take: 25 });

                phraseSections.forEach(x => {
                    if (x.phraseSectionType.parentPhraseSectionTypeId == null &&
                        x.showInDetails && tree.find(y => y.phraseSectionTypeId === x.phraseSectionTypeId) == null) {
                        if (x.phraseSectionTypeId === phraseSectionPh ||
                            x.phraseSectionTypeId === phraseSectionInfo ||
                            x.phraseSectionTypeId === phraseSectionIn ||
                            x.phraseSectionTypeId === phraseSectionTrans) {
                            if (x.phraseSectionTypeId === phraseSectionPh &&
                                (document.documentProperties == null || (document.documentProperties.physicalProperties.length === 0 && document.documentProperties.hmisProperties.length === 0 && document.documentProperties.nfpaProperties.length === 0)))
                                return;
                            if (x.phraseSectionTypeId === phraseSectionIn && (fullIngredients.length === 0 && standardIngredients.length === 0))
                                return;
                            if (x.phraseSectionTypeId === phraseSectionTrans && (document.transportation.length === 0))
                                return;
                        }
                        else
                            return;
                        x.children = [];
                        x.show = true;
                        x.label = props.localizations[x.phraseSectionType.name] ?? x.phraseSectionType.name;
                        x.value = x.phraseSectionId;
                        tree.push(x);
                    }
                });

                tree = tree.sort((a, b) => a.sortOrder - b.sortOrder).filter(x => x.showInDetails);

                setsectionsTree(tree);

                setsectionsDropdownData(JSON.parse(JSON.stringify(tree)));
                setphraseSectionsSelected(JSON.parse(JSON.stringify(tree)));

                setselectedDoc(document);
            }

            setPending(true);
            execute(
                "GET",
                `${apiRoutes.documents.name}?fileName=${documentName}`
            ).then((json) => {
                setPending(false);
                if (json.fileName.indexOf(".docx") > 0) {
                    const link = document.createElement("a");
                    link.href =
                        "data:application/vnd.openxmlformats-officedocument.wordprocessingml.document;base64," +
                        json.fileData;
                    link.download = json.documentName;
                    link.click();
                }
                if (json.fileName.indexOf(".pdf") > 0) {
                    var blob = {};
                    var arrrayBuffer = base64ToArrayBuffer(json.fileData);
                    blob = new Blob([arrrayBuffer], { type: "application/pdf" });
                    var link = window.URL.createObjectURL(blob);
                    setDocumentLink(link);
                }
            });
        }
    };

    const handlePhrasesDropdownChange = (e) => {
        sectionsDropdownData.forEach(x => {
            x.show = false;
        });
        e.value.forEach(x => {
            let phrase = sectionsDropdownData.find(y => x.value === y.value);
            if (phrase)
                phrase.show = true;
        });

        phraseSections.forEach(x => {
            if (x.phraseSectionType.parentPhraseSectionTypeId == null)
                x.show = false;
        });
        e.value.forEach(x => {
            let phrase = phraseSections.find(y => x.phraseSectionId === y.phraseSectionId);
            if (phrase)
                phrase.show = true;
        });
        let phrases = e.value;
        setphraseSectionsSelected(phrases);
        setphraseSections(phraseSections);
        setsectionsDropdownData(sectionsDropdownData);
    };

    const [status, setStatus] = React.useState(statusTemp);

    const handleStatusChange = () => {
        let filteredDocs = originalDocuments;
        if (status)
            filteredDocs = filteredDocs.filter(x => x.status === 1);

        setCookie("SpheraDocumentFilterStatus", !status);
        setStatus(!status);

        setdocuments(filteredDocs);
        setDataResult(process(filteredDocs, dataState));
    }

    const getCorrectLocalization = (synonyms, cultureInfoCode, IsChemicalName, IsMfgName) => {
        let synType = 2;
        if (IsChemicalName)
            synType = 3;
        if (IsMfgName)
            synType = 4;

        if (synonyms == null || synonyms.length === 0)
            return "";

        let localization = synonyms.find(x => x.cultureInfoCode === cultureInfoCode && x.synonymType === synType);
        if (localization != null)
            return localization.synonym;

        let languageSection = (cultureInfoCode.indexOf("-") !== -1) ? cultureInfoCode.substring(0, cultureInfoCode.indexOf("-") + 1) : cultureInfoCode;
        localization = synonyms.find(x => x.cultureInfoCode.indexOf(languageSection) !== -1 && x.synonymType === synType);
        if (localization != null)
            return localization.synonym;

        localization = synonyms.find(x => x.cultureInfoCode.indexOf("en-") !== -1 && x.synonymType === synType);
        if (localization != null)
            return localization.synonym;


        localization = synonyms.find(x => x.synonymType === synType);
        if (localization != null)
            return localization.synonym;

        if (IsMfgName)
            return null;

        return "";
    }

    const getData = () => {
        setPending(true);
        execute("GET",
            `${apiRoutes.documents.getPhraseSections}`).then(async (response) => {
                if (response != null) {
                    setphraseSections(response.sections);
                }
            });
        execute("GET",
            `${apiRoutes.column.GetColumns}`).then(async (response) => {
                if (response != null) {
                    processColumns(response, props.languageValue.localeId);
                }
            });
        execute(
            "GET",
            `${apiRoutes.materials.get(MaterialId, localStorage.getItem("languages"))}`
        ).then((json) => {
            if (json != null) {
                let newDocuments = json.documents;
                newDocuments.forEach((doc) => {
                    doc.sensitivity = sensitivityLevels.find(x => x.sensitivityId === doc.sensitivity).sensitivity;
                    doc.statusText = statusTypes.find(x => x.statusId === doc.status).status;

                    doc.languagesText = doc.languages.map((x) => x.languageName + "-" + x.country).join(', ');

                    if (doc.regulatoryJurisdiction == null || doc.regulatoryJurisdiction === "")
                        doc.regulatoryJurisdiction = props.localizations.Global;

                    doc.revisionDate = new Date(new Date(doc.revisionDate).toDateString());

                    doc.timestampCreateDisplay = adjustForTimezone(doc.timestampCreate.substring(0, doc.timestampCreate.indexOf("+")), props.customer.TimezoneId);
                    doc.timestampCreate = new Date(adjustForTimezone(doc.timestampCreate.substring(0, doc.timestampCreate.indexOf("+")), props.customer.TimezoneId).toDateString());
                    if (doc.timestampModified != null) {
                        doc.timestampModifiedDisplay = adjustForTimezone(doc.timestampModified.substring(0, doc.timestampModified.indexOf("+")), props.customer.TimezoneId);
                        doc.timestampModified = new Date(adjustForTimezone(doc.timestampModified.substring(0, doc.timestampModified.indexOf("+")), props.customer.TimezoneId).toDateString());
                    }
                });

                if (json.timestampCreate != null) {
                    let timestampCreateTimezone = adjustForTimezone(json.timestampCreate.substring(0, json.timestampCreate.indexOf("+")), props.customer.TimezoneId);
                    let formatedDate = formatDate(timestampCreateTimezone, props.customer.DateFormatId, true);
                    json.timestamp_Create = formatedDate;
                }
                if (json.timestampModified != null) {
                    let timestampModifiedTimezone = adjustForTimezone(json.timestampModified.substring(0, json.timestampModified.indexOf("+")), props.customer.TimezoneId);
                    let formatedDate = formatDate(timestampModifiedTimezone, props.customer.DateFormatId, true);
                    json.timestamp_Modified = formatedDate;
                }

                json.synonymsString = [...new Set(json.synonyms.filter(x => x.synonymType === 1).map((x) => x.synonym))].join(', ');
                if (json.synonymsString.length > 200)
                    json.synonymsString = json.synonymsString.substring(0, 200) + "...";
                json.materialCodesText = json.materialCodes.join(', ');
                setsynonyms(json.synonyms.filter(x => x.synonymType === 1));

                json.materialName = getCorrectLocalization(json.synonyms, props.languageValue.languageCode, false, false);
                json.chemicalName = getCorrectLocalization(json.synonyms, props.languageValue.languageCode, true, false);
                json.manufacturer = getCorrectLocalization(json.synonyms, props.languageValue.languageCode, false, true) ?? json.manufacturer;

                if (json.casNumber != null) {
                    let length = json.casNumber.length;
                    json.CasNumberString = json.casNumber;
                    if (json.casNumber.indexOf("-") === -1 && json.casNumber.length > 0)
                        json.CasNumberString = json.casNumber.slice(0, length - 3) + "-" + json.casNumber.slice(length - 3, length - 1) + "-" + json.casNumber.slice(length - 1);
                }

                setmaterialData(json);
                setoriginalDocuments(newDocuments);

                let filteredDocs = newDocuments;
                if (!status)
                    filteredDocs = filteredDocs.filter(x => x.status === 1);


                setdocuments(filteredDocs);
                setDataResult(process(filteredDocs, dataState));
                setPending(false);
            }
        });
    };

    const [gotData, setgotData] = React.useState(false);
    React.useEffect(() => {
        if (props.localizations.Search == null || result == null || gotData)
            return;

        setgotData(true);
    }, [props.localizations.Search, result]);

    React.useEffect(() => {
        if (gotData)
            getData();
    }, [gotData]);

    const processColumns = (columns, languageId) => {
        let count = 0;
        let newOrder = 99;
        let docColumns = [];
        let matColumns = [];
        let matColumnsOriginal = [];
        for (var i = 0; i < columns.length; i++) {
            if (columns[i].gridId === 2) {
                let newCol = {};
                newCol.width = 150;
                newCol.field = columns[i].field;
                for (var j = 0; j < columns[i].columnPropertyValues.length; j++) {
                    newCol[columns[i].columnPropertyValues[j].columnProperty.columnProperty1.trim()] = columns[i].columnPropertyValues[j].columnPropertyValue1.trim();
                }

                if (columns[i].customerColumns[0] != null) {
                    count++;
                    newCol.show = columns[i].customerColumns[0].selected;
                    newCol.order = columns[i].customerColumns[0].columnOrder;
                    newCol.width = columns[i].customerColumns[0].width;
                }
                else {
                    newCol.show = false;
                    newCol.order = newOrder;
                    newOrder++;
                }

                if (columns[i].customerColumns.length > 0) {
                    let used = false;
                    for (var j = 0; j < columns[i].customerColumns[0].columnLocalizations.length; j++) {
                        if (languageId === columns[i].customerColumns[0].columnLocalizations[j].localeId && columns[i].customerColumns[0].columnLocalizations[j].localization) {
                            newCol.Title = columns[i].customerColumns[0].columnLocalizations[j].localization;
                            used = true;
                        }
                    }

                    if (!used || !newCol.Title) {
                        let enLoc = columns[i].customerColumns[0].columnLocalizations.find(x => x.localeId === 1033);
                        if (enLoc != null && enLoc.localization)
                            newCol.Title = enLoc.localization;
                        else if (columns[i].customerColumns[0].columnLocalizations.length > 0 && columns[i].customerColumns[0].columnLocalizations[0].localization)
                            newCol.Title = columns[i].customerColumns[0].columnLocalizations[0].localization;
                        else
                            newCol.Title = props.localizations[newCol.Title];
                    }

                    if (!newCol.Title)
                        newCol.Title = columns[i].field;
                }

                docColumns.push(newCol);
            }
            else {
                let col = {
                    columnId: columns[i].columnId,
                    field: columns[i].field,
                    show: columns[i].customerColumns[0].selected
                };
                if (col.show) {
                    if (columns[i].customerColumns.length > 0) {
                        let used = false;
                        for (var j = 0; j < columns[i].customerColumns[0].columnLocalizations.length; j++) {
                            if (languageId === columns[i].customerColumns[0].columnLocalizations[j].localeId && columns[i].customerColumns[0].columnLocalizations[j].localization) {
                                col.localization = columns[i].customerColumns[0].columnLocalizations[j].localization;
                                used = true;
                            }
                        }

                        if (!used || !col.localization) {
                            for (var j = 0; j < columns[i].columnPropertyValues.length; j++) {
                                if (columns[i].columnPropertyValues[j].columnPropertyId == 1)
                                    col.localization = props.localizations[columns[i].columnPropertyValues[j].columnPropertyValue1.trim()];
                            }
                            let enLoc = columns[i].customerColumns[0].columnLocalizations.find(x => x.localeId === 1033);
                            if (enLoc != null && enLoc.localization)
                                col.localization = enLoc.localization;
                            else if (columns[i].customerColumns[0].columnLocalizations.length > 0 && columns[i].customerColumns[0].columnLocalizations[0].localization)
                                col.localization = columns[i].customerColumns[0].columnLocalizations[0].localization;
                        }

                        if (!col.localization)
                            col.localization = columns[i].field;
                    }
                }

                if (columns[i].customerColumns[0].selected)
                    matColumns.push(col);
                matColumnsOriginal.push(columns[i]);
            }
        }
        if (count == 0) {
            for (var i = 0; i < docColumns.length; i++) {
                docColumns[i].show = true;
                docColumns[i].order = i;
            }
        }
        if (props.currentTenent.IsEditSensitivityUser || props.currentTenent.IsDeleteDocumentsUser)
            docColumns.unshift({
                "Title": "SelectRow",
                "field": "selected",
                "show": true,
                "IsCheckbox": true,
                "noFilterOrSort": true,
                "AlwaysShow": true,
                "ExcludeFromExport": true,
                "order": -1
            });



        let matCol = matColumns.find(x => x.field === "materialName");
        let casCol = matColumns.find(x => x.field === "casNumber");
        let manuCol = matColumns.find(x => x.field === "manufacturer");

        sethasCas(casCol != null);
        sethasManu(manuCol != null);
        setmanuLoc(manuCol ? manuCol.localization : "");
        setcasLoc(casCol ? casCol.localization : "");
        setnameLoc(matCol ? matCol.localization : "");

        setdocumentColumns(docColumns.sort((a, b) => a.order - b.order));
        setmaterialColumns(matColumns.sort((a, b) => a.order - b.order));
        setColumnsOriginal(columns);
    };

    const [isCopied, setIsCopied] = React.useState(false);
    const [copyText, setCopyText] = React.useState("");
    const popoverAnchor = React.useRef(null);
    const [showCopiedPopover, setShowCopiedPopover] = React.useState(false);
    async function copyTextToClipboard(text) {
        if ("clipboard" in navigator) {
            return await navigator.clipboard.writeText(text);
        } else {
            return document.execCommand("copy", true, text);
        }
    }

    const handleCopyClick = (copiedText) => {
        setShowCopiedPopover(!showCopiedPopover);
        copyTextToClipboard(copiedText)
            .then(() => {
                setCopyText(copiedText);
                setIsCopied(true);
                setTimeout(() => {
                    setIsCopied(false);
                    setShowCopiedPopover(false);
                }, 3000);
            })
            .catch((err) => {
                console.log(err);
            });
    };

    React.useEffect(() => {
        document.addEventListener("click", (e) => {
            if (show && e.target.className !== 'icon-ui-general-settings icon l-icon')
                setShow(false)
        });
    }, [show]);

    React.useEffect(() => {
        let data = materialData;
        data.materialName = getCorrectLocalization(data.synonyms, props.languageValue.languageCode, false, false);
        data.chemicalName = getCorrectLocalization(data.synonyms, props.languageValue.languageCode, true, false);
        data.manufacturer = getCorrectLocalization(data.synonyms, props.languageValue.languageCode, false, true) ?? data.manufacturer;

        setmaterialData(data);

        processColumns(ColumnsOriginal, props.languageValue);
    }, [props.localizations]);

    return (
        <Layout
            versionNumber={props.versionNumber}
            errorMessage={errorMessage}
            localizations={props.localizations}
            languageValue={props.languageValue}
            currentTenent={props.currentTenent}
            success={success}
            setSuccess={setsuccess}
            error={error}
            setError={seterror}
        >
            <div className="gridLinkDiv">
                <a className="gridLink" onClick={backToSearchClick} onKeyDown={(e) => { if (e.code === "Enter") backToSearchClick(); }}>
                    {props.localizations.BackToSearch}
                </a>
            </div>

            <div
                style={{ paddingTop: "5px", paddingLeft: "5px", fontSize: "16px" }}
                className="row"
            >
                <div className="col" style={{ maxWidth: "fit-content" }}>
                    <p style={{ color: "#2b2d42", fontFamily: "Inter" }}>
                        <strong>{nameLoc}:</strong>{" "}
                        <span className="selectable">{materialData.materialName}</span>
                    </p>
                </div>
                <div className="col" style={{ maxWidth: "fit-content" }}>
                    <p style={{ color: "#2b2d42", fontFamily: "Inter" }} className={hasManu ? "" : "hidden"}>
                        <strong>{manuLoc}:</strong>{" "}
                        <span className="selectable">{materialData.manufacturer}</span>
                    </p>
                </div>
                <div className="col" style={{ maxWidth: "fit-content" }}>
                    <p style={{ color: "#2b2d42", fontFamily: "Inter" }} className={hasCas ? "" : "hidden"} >
                        <strong>{casLoc}:</strong>{" "}
                        <span className="selectable">{materialData.CasNumberString}</span>
                    </p>
                </div>
            </div>
            <div className="materialDetailsTab">
                <SpheraTab>
                    <SpheraSubTab title={props.localizations.Documents} >
                        <div>
                            <DocumentDetailsGrid
                                documentColumns={documentColumns}
                                localizations={props.localizations}
                                pending={pending}
                                documents={documents}
                                dataState={dataState}
                                dataResult={dataResult}
                                materialData={materialData}
                                dataStateChange={dataStateChange}
                                toggle={toggle}
                                handleStatusChange={handleStatusChange}
                                status={status}
                                customer={props.customer}
                                checkboxChange={checkboxChange}
                                checkAllChange={checkAllChange}
                                toggleSensitivity={toggleSensitivity}
                                checkAllChecked={checkAllChecked}
                                currentTenent={props.currentTenent}
                                handleContextMenu={handleContextMenu}
                            />
                        </div>
                    </SpheraSubTab>
                    <SpheraSubTab title={props.localizations.MaterialDetails} >
                        <div className="row">
                            <div className="col-4" >
                                <p style={{ color: "#2b2d42", fontFamily: "Inter" }}>
                                    <strong>{props.localizations.ChemicalName}:</strong>{" "}
                                    <span className="selectable">{materialData.chemicalName}</span>
                                </p>
                            </div>
                            <div className="col-4">
                                <p style={{ color: "#2b2d42", fontFamily: "Inter" }}>
                                    <strong>{props.localizations.Synonyms}:</strong>{" "}
                                    <span className="selectable">{materialData.synonymsString} {materialData.synonymsString != null && materialData.synonymsString.length > 0 ? allSynsLink : ""}</span>
                                </p>
                            </div>
                            <div className="col-4">
                                <p style={{ color: "#2b2d42", width: "fit-content", }} >
                                    <strong>{props.localizations.MaterialId}:</strong>{" "}
                                    <input type="hidden" value={copyText}></input>

                                    {!isCopied && (
                                        <i className="icon-ui-general-page"
                                            onClick={(e) => handleCopyClick(materialData.materialId)}
                                            onKeyDown={(e) => { if (e.keyCode === 13) handleCopyClick(materialData.materialId); }}
                                            style={{ background: "#009BDE" }}
                                        ></i>
                                    )}
                                    {isCopied && <i class="icon-ui-general-check" style={{ background: "#33BB44" }}></i>}
                                    <span
                                        className="selectable material-id"
                                        onClick={(e) => handleCopyClick(materialData.materialId)}
                                        onKeyDown={(e) => { if (e.code === "Enter") handleCopyClick(materialData.materialId); }}
                                    >
                                        {materialData.materialId}
                                    </span>
                                </p>
                            </div>
                            {materialColumns.map(
                                (column, idx) => {
                                    if (column.field == "casNumber" || column.field == "materialName" || column.field == "manufacturer" || !column.show)
                                        return "";
                                    else
                                        return (
                                            <div className="col-4" key={idx} >
                                                <p style={{ color: "#2b2d42", fontFamily: "Inter" }}>
                                                    <strong>{column.localization}:</strong>{" "}
                                                    <span className="selectable">{materialData[column.field]}</span>
                                                </p>
                                            </div>);
                                }
                            )}
                        </div>
                    </SpheraSubTab>
                </SpheraTab>
            </div>
            <Popup show={show} offset={offSet.current}>
                <Menu
                    vertical={true}
                    style={{
                        display: "inline-block",
                    }}
                    onSelect={documentsMenuClick}
                >
                    {(props.currentTenent.IsEditSensitivityUser) &&
                        <MenuItem text={props.localizations.ChangeSensitivity} />
                    }
                    {(props.currentTenent.IsDeleteDocumentsUser) &&
                        <MenuItem text={props.localizations.DeleteDocuments} />
                    }
                </Menu>
            </Popup>
            <Popover
                show={showCopiedPopover}
                anchor={popoverAnchor.current}
                position="top"
            >
                Copied!
            </Popover>
            <DocumentModal
                localizations={props.localizations}
                pending={pending}
                toggle={toggle}
                showModal={showModal}
                documentLink={documentLink}
                selectedDoc={selectedDoc}
                documentColumns={documentColumns}
                standardDataStateChange={standardDataStateChange}
                standardDataState={standardDataState}
                standardDataResult={standardDataResult}
                standardIngredients={standardIngredients}
                fullDataStateChange={fullDataStateChange}
                fullDataState={fullDataState}
                fullDataResult={fullDataResult}
                fullIngredients={fullIngredients}
                customer={props.customer}
                sectionsTree={sectionsTree}
                languageValue={props.languageValue}
                handlePhrasesDropdownChange={handlePhrasesDropdownChange}
                phraseSectionsSelected={phraseSectionsSelected}
                sectionsDropdownData={sectionsDropdownData}
                transportationDataStateChange={transportationDataStateChange}
                Transportation={transportation}
                transportationDataState={transportationDataState}
                transportationDataResult={transportationDataResult}
            ></DocumentModal>
            <MaterialSynoymsModal
                localizations={props.localizations}
                pending={pending}
                toggleSyns={showSynonymsModal}
                showSynsModal={showSynsModal}
                synonyms={synonyms}
            ></MaterialSynoymsModal>

            <ChangeDocumentSensitivityModal
                modalPending={modalPending}
                localizations={props.localizations}
                toggleSensitivity={toggleSensitivity}
                showSensitivityModal={showSensitivityModal}
                changeSensitivityValue={changeSensitivityValue}
                setchangeSensitivityValue={setchangeSensitivityValue}
                submitChangeDocumentSensitivity={submitChangeDocumentSensitivity}
            ></ChangeDocumentSensitivityModal>

            <DeleteDocumentsModal
                modalPending={modalPending}
                localizations={props.localizations}
                toggleDeleteDocuments={toggleDeleteDocuments}
                showDeleteDocumentsModal={showDeleteDocumentsModal}
                submitDeleteDocuments={submitDeleteDocuments}
            ></DeleteDocumentsModal>
        </Layout>
    );
}
