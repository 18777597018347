import React from 'react';
import ReactDOM from 'react-dom';
import { SpheraSpinner } from "@sphera-cloud/component-ui-reactjs";

export function LoadingPanel(props) {
    const loadingPanel = (
        <div className="k-loading-mask">
            <span className="k-loading-text">{props.localizations.Loading}</span>
            <SpheraSpinner version={1} color="dark-1" />
        </div>
    );

    const gridContent = document && document.querySelector(".k-grid-content");

    return gridContent ? ReactDOM.createPortal(loadingPanel, gridContent) : loadingPanel;
}