import React from "react";
import { SpheraForm, SpheraFormElement, SpheraButtonEx, SpheraCheckbox, SpheraField, SpheraInput, SpheraTextAreaFields, SpheraTooltip, SpheraDatePicker
} from "@sphera-cloud/component-ui-reactjs";
import "./EditTokenForm.css";
import { apiRoutes } from "../../apiRoutes";
import useFetchWithMsal from "../../hooks/useFetchWithMsal";
import { LoadingPanel } from '../../components/LoadingPanel';

export const EditTokenForm = (props) => {
  const [success, setSuccess] = React.useState(false);
  const [error, setError] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState("");

  const handleSubmit = (dataItem) => {
    const postData = {
      name: dataItem?.tokenName,
      description: dataItem?.description ? dataItem?.description : '',
      expiredAt: dataItem?.expiryDateTime,
      permissions: +dataItem?.readPermission | (+dataItem?.writePermission+1) ,
      isEnabled: true,
    };
    
    props.setPending(true);
    if (props.isNew) {
      execute("POST", apiRoutes.tokens.post, postData)
        .then((json) => {
          if (!json) {
            setErrorMessage(props.localizations.EmailNotUnique);
            setError(true);
          } else {
            setTimeout(function () {
              setSuccess(false);
            }, 5000);
            setSuccess(true);
            
            execute("GET", apiRoutes.tokens.get).then((json) => {
              if (json !== null) {
                props.setApiTokens(json);
                props.setIsData(true);
                const editableCards = [];

                json?.forEach((apiToken, key) =>
                  editableCards.push({ isEdit: false, apiToken: apiToken })
                );
                props.setEditableCards(editableCards);
                props.setIsNew(false);
              }
            });
          }
        })
        .catch((err) => {
          setError(true);
          setErrorMessage(props.localizations.UnableToAddToken);
        })
        .finally(() => {
          props.setPending(false);
        });
    } else {
      execute("PUT", apiRoutes.tokens.put(dataItem?.id), postData)
        .then((json) => {
          if (!json) {
            setErrorMessage(props.localizations.EmailNotUnique);
            setError(true);
          } else {
            setTimeout(function () {
              setSuccess(false);
            }, 5000);
            setSuccess(true);
            props.setIsNew(false);
            execute("GET", apiRoutes.tokens.get).then((json) => {
              if (json !== null) {
                props.setApiTokens(json);
                props.setIsData(true);
                const editableCards = [];

                json?.forEach((apiToken, key) =>
                  editableCards.push({ isEdit: false, apiToken: apiToken })
                );
                props.setEditableCards(editableCards);
              }
            });
          }
        })
        .catch((err) => {
          setError(true);
          setErrorMessage(props.localizations.UnableToAddToken);
        })
        .finally(() => {
            props.setPending(false);
        });
    }
  };

  const handleReset = (resetProps) => {
    resetProps.onFormReset();
    execute("GET", apiRoutes.tokens.get).then((json) => {
      if (json !== null) {
        props.setApiTokens(json);
          props.setPending(false);
        props.setIsData(true);
        const editableCards = [];

        json?.forEach((apiToken, key) =>
          editableCards.push({ isEdit: false, apiToken: apiToken })
        );
        props.setEditableCards(editableCards);
      }
    });
  };

  const requiredField = (value) =>
    !value || value?.length === 0 ? props.localizations.FieldRequired : "";

  const checkRequiredAndExpirationField = (value) => {
    if (!value || value?.length === 0) return "";
    if (new Date() >= new Date(value))
      return props.localizations.ExpirationError;
    return "";
  };

  const { execute } = useFetchWithMsal({
    scopes: props.loginConfig.protectedResources.apiDocumentList.scopes.read,
  });

  return (
    <SpheraForm
      initialValues={{
        id: props.card.apiToken?.id,
        tokenName: props.card.apiToken?.name,
        description: props.card.apiToken?.description,
        expiryDateTime: props.card.apiToken?.expiryDateTime,
        readPermission: props.isNew
          ? true
          : props.card.apiToken?.permissions === 1 || props.card.apiToken?.permissions === 3,
        writePermission: props.card.apiToken?.permissions === 2 || props.card.apiToken?.permissions === 3,
      }}
      onSubmit={handleSubmit}
      render={(formRenderProps) => {
        return (
          <SpheraFormElement noValidate>
            {props.pending && <LoadingPanel localizations={props.localizations} />}
            <fieldset className="k-form-fieldset">
              <div className="row">
                <div className="col-12">
                  <SpheraTooltip position="top">
                    <SpheraField
                      title={props.localizations.TokenName}
                      name="tokenName"
                      required
                      label={props.localizations.TokenName}
                      component={SpheraInput}
                      type="text"
                      placeholder={props.localizations.TokenName}
                      validator={requiredField}
                      width="290px"
                      autoFocus
                      valid={props.firstNameValid}
                      validationMessage={
                        props.localizations.FirstNameValidation
                      }
                      maxLength={30}
                      touched={!props.firstNameValid}
                    />
                  </SpheraTooltip>
                </div>
                <div className="mt-2">
                  <div className="col-12">
                    <SpheraField
                      name="expiryDateTime"
                      label={props.localizations.ExpirationDate}
                      component={SpheraDatePicker}
                      placeholder="Date..."
                      width="290px"
                      format="dd-MM-yyyy"
                      validator={checkRequiredAndExpirationField}
                    />
                  </div>
                </div>
              </div>
              <div className="row mt-3">
                <div className="col-10">
                  <div style={{ width: "320px", wordBreak: 'break-word'}}>
                    <SpheraField
                      name="description"
                      label={props.localizations.Description}
                      component={SpheraTextAreaFields}
                      maxLength={255}
                      width="290px"
                      style={{ width: "290px", minWidth: "290px" }}
                    />
                  </div>
                </div>
              </div>
            </fieldset>
            <div className="k-form-buttons row">
              <div className="float-left col-6">
                <SpheraButtonEx
                  text={props.localizations.Cancel}
                  type="button"
                  secondary
                  onClick={() => {
                    handleReset(formRenderProps);
                    props.toggleEdit(props.card);
                  }}
                />
              </div>
              {props.isNew ? (
                <div className="float-right col-6">
                  <SpheraButtonEx
                    text={props.localizations.Save}
                    primary={true}
                    type="submit"
                    disabled={!formRenderProps.allowSubmit}
                    className="btn-primary sphera-button float-start"
                  />
                </div>
              ) : (
                <div className="float-right col-6">
                  <SpheraButtonEx
                    text={props.localizations.Update}
                    primary={true}
                    type="submit"
                    className="btn-primary sphera-button float-end mx-1"
                  />
                </div>
              )}
            </div>
          </SpheraFormElement>
        );
      }}
    />
  );
};
