import React from 'react';
import '../../styles/appFooter.css';

export function AppFooter(props) {
    return(
		<div className="footer footerFixed" >
            <span data-testid="versionSection" className="versionSection">{props.localizations.Version} {props.versionNumber?.version} {props.versionNumber?.buildDate}</span>
            <span className="copyright" id="copyrightYear">{props.localizations.Copyright}</span>
        </div>
    );
}