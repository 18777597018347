import React from "react";
import { SpheraCheckbox } from "@sphera-cloud/component-ui-reactjs";

export function SortableCustomerProperty(props) {
    const {
        style,
        attributes,
        dataItem,
        forwardRef,
        isActive
    } = props;

    return <div ref={forwardRef} {...attributes} style={{
        ...style,
        border: isActive ? '2px dashed black' : 0,
        paddingTop: 1,
        paddingBottom: 1,
        paddingLeft: 2,
        paddingRight: 2
    }}>
        <div className="row rowHover">
            <div className="col-1 customerColumnTitlePadding" >
                <div className="pick-list-container-draggable">
                    <div className="pick-list-draggable">
                        <i className="icon-ui-general-draggable-handle-picklist"></i>
                    </div>
                </div>
            </div>
            <div className="col-4 customerColumnTitlePadding" >
                <span>{props.localizations[dataItem.title] == null ? dataItem.title : props.localizations[dataItem.title]}</span>
            </div>
            <div className="col-4 customerColumnTitlePadding" id="phraseRbg">
                <SpheraCheckbox primary={true} value={dataItem.Include == null ? dataItem.ShowInDetails === 1 : dataItem.Include} onChange={(e) => props.propscheckChange(dataItem.PhraseSectionTypeId ?? dataItem.phraseSection.phraseSectionTypeId)} />
            </div>
        </div>
    </div>;
};