import React from 'react';
import { Toolbar, ToolbarSpacer } from "@progress/kendo-react-buttons";
import { SpheraButtonEx, SpheraButtonGroup } from "@sphera-cloud/component-ui-reactjs";

export const PublicSitesToolbar = (props) => {

  const createNewSite = (event) => {
    props.setIsSiteFormValid(true);
    props.addSiteClick();
  };

  return (
    <Toolbar>
      <ToolbarSpacer />
      <SpheraButtonGroup>
        <SpheraButtonEx
          text={props.localizations.AddSite}
          onClick={(event) => {createNewSite(event);}}
        />
      </SpheraButtonGroup>
    </Toolbar>
  );
};
