import React from "react";
import { SpheraConfirmation } from "@sphera-cloud/component-ui-reactjs";

export const ConfirmDeleteWidgetDialog = (props) => {
	return (
		<SpheraConfirmation
			hasAction
			isDangerConfirm
			actionText={props.localizations.Delete}
			cancelText={props.localizations.Cancel}
			onCancel={props.toggleConfirmDeleteDialog}
			onClose={props.toggleConfirmDeleteDialog}  
			title={props.localizations.DeleteWidgetName + props.card?.name}
			onAction={() => props.deleteWidget(props.card)}
		>
			{props.localizations.DeleteWidget}
		</SpheraConfirmation>
	);
};
