import React from "react";
import { Col, Row } from 'reactstrap';
import { SpheraInput, SpheraCheckbox } from "@sphera-cloud/component-ui-reactjs";

export function SortableMaterialColumn(props) {
    const {
        style,
        attributes,
        dataItem,
        forwardRef,
        isActive,
        currentLocaleId
    } = props;

    let localization = null;
    if (dataItem.customerColumns != null && dataItem.customerColumns[0] != null && dataItem.customerColumns[0].columnLocalizations.length > 0) {
        let loc = dataItem.customerColumns[0].columnLocalizations.find(x => x.localeId === currentLocaleId);
        if(loc != null )
            localization = loc.localization;
    }
    if (dataItem.alwaysShow)
        dataItem.selected = true;

    return <div ref={forwardRef} {...attributes} style={{
        ...style,
        border: isActive ? '2px dashed black' : 0,
        paddingTop: 1,
        paddingBottom: 1,
        paddingLeft: 2,
        paddingRight: 2
    }}>
        <Row className="rowHover">
            <Col className="customerColumnTitlePadding" lg="1" >
                <div className="pick-list-container-draggable">
                    <div className="pick-list-draggable">
                        <i className="icon-ui-general-draggable-handle-picklist"></i>
                    </div>
                </div>
            </Col>
            <Col className="customerColumnTitlePadding" lg={props.isSettings ? "3" : "4"} >
                <span>{props.localizations[dataItem.title] == null ? dataItem.title : props.localizations[dataItem.title]}</span>
            </Col>
            <Col className="customerColumnTitlePadding" id="phraseRbg" lg={props.isSettings ? "2" : "4"}>
                <SpheraCheckbox primary={true} value={dataItem.alwaysShow ? true : dataItem.customerColumns != null ? dataItem.customerColumns[0].selected : dataItem.selected} disabled={dataItem.alwaysShow} onChange={(e) => props.includecustomcolumn1Change(dataItem.columnId, dataItem.gridId ? dataItem.gridId : dataItem.column.gridId )} />
            </Col>
            {props.isSettings &&
                <>
                    <Col id="phraseRbg" lg="3">
                        <SpheraInput
                        title="customcolumn1"
                        name="customcolumn1"
                        placeholder="Column Name"
                        type="text"
                        onBlur={(e) => props.customcolumn1Change(e, dataItem.columnId, dataItem.gridId)}
                        value={localization}
                    />
                    </Col>
                    <Col className="customerColumnLogoPadding" id="phraseRbg" lg="1">
                    <i className="icon-ui-general-chat localizationLink" onClick={(e) => props.toggleCustomColumn(dataItem.columnId, dataItem.gridId)}></i>
                    </Col>
                </>
            }
            <Col className="customerColumnTitlePadding" id="colWidth" lg="2">
                {dataItem.width}
            </Col>
        </Row>
    </div>;
};