import { LogLevel } from "@azure/msal-browser";

export function authConfig(props)
{
    const msalConfig = {
        auth: {
            clientId: props.clientId, // This is the ONLY mandatory field that you need to supply.
            authority: props.authority, // Choose SUSI as your default authority.
            knownAuthorities: [props.authorityDomain], // Mark your B2C tenant's domain as trusted.
            redirectUri: '/', // You must register this URI on Azure Portal/App Registration. Defaults to window.location.origin
            postLogoutRedirectUri: '/', // Indicates the page to navigate after logout.
            navigateToLoginRequestUrl: false, // If "true", will navigate back to the original request location before processing the auth code response.
        },
        cache: {
            cacheLocation: 'sessionStorage', // Configures cache location. "sessionStorage" is more secure, but "localStorage" gives you SSO between tabs.
            storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
        },
        system: {
            loggerOptions: {
                loggerCallback: (level, message, containsPii) => {
                    if (containsPii) {
                        return;
                    }
                    switch (level) {
                        case LogLevel.Error:
                            console.error(message);
                            return;
                        case LogLevel.Verbose:
                            console.debug(message);
                            return;
                        case LogLevel.Warning:
                            console.warn(message);
                            return;
                        default:
                            return;
                    }
                },
            },
        },
    };

    const protectedResources = {
        apiDocumentList: {
            scopes: {
                read: [props.readScope],
                write: [props.writeScope],
            },
        },
    };

    const loginRequest = {
        scopes: [props.readScope, props.writeScope],
    };

    return { msalConfig, protectedResources, loginRequest };
}