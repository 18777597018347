import React from "react";
import { SpheraInput, SpheraTextAreaFields, SpheraButtonEx, SpheraForm, SpheraFormElement, SpheraField, SpheraTooltip
} from "@sphera-cloud/component-ui-reactjs";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";

export const AddWidgetModal = (props) => {
  const requiredField = (value) =>
    !value || value?.length === 0 ? props.localizations.FieldRequired : "";

  const validUrlField = (value) => {
    if (!value || value?.length === 0) 
      return props.localizations.FieldRequired;

  }

  const formSubmitRef = React.useRef();
  const formResetRef = React.useRef();

  return (
    <>
      <Modal
        isOpen={props.showModal}
        toggle={props.toggleModal}
        style={{ width: "400px" }}
      >
        <ModalHeader toggle={props.toggleModal}>
          {props.localizations.AddWidget}
        </ModalHeader>
        <ModalBody>
          <div className="d-flex p-3 justify-content-center container-fluid mx-auto ">
            <SpheraForm
              initialValues={{
                name: "",
                description: "",
                domain: 'localhost',
                settings: '',
                isEnabled: true
              }}
              onSubmit={(dataItem, e) => {
                props.modalSaveClick(dataItem);
              }}
              render={(formRenderProps) => {  
                return (
                  <SpheraFormElement noValidate>
                    <fieldset className="k-form-fieldset">
                      <div className="row mb-3">
                        <div className="col-12">
                          <SpheraTooltip position="top">
                            <SpheraField
                              title={props.localizations.Name}
                              name="name"
                              required
                              label={props.localizations.Name}
                              component={SpheraInput}
                              type="text"
                              placeholder={props.localizations.Name}
                              validator={requiredField}
                              width="auto"
                              maxLength={30}
                            />
                          </SpheraTooltip>
                        </div>
                      </div>
                      <div className="row mb-3">
                        <div className="col-12">
                          <SpheraTooltip position="top">
                            <SpheraField
                              title={props.localizations.Description}
                              name="description"
                              label={props.localizations.Description}
                              component={SpheraTextAreaFields}
                              type="text"
                              placeholder={props.localizations.Description}
                              width="auto"
                              maxLength={255}
                            />
                          </SpheraTooltip>
                        </div>
                      </div>
                      <div className="row mb-3">
                        <div className="col-12">
                          <SpheraTooltip position="top">
                            <SpheraField
                              title={props.localizations.Domain}
                              name="domain"
                              required
                              label={props.localizations.Domain}
                              component={SpheraInput}
                              type="text"
                              validator={validUrlField}
                              width="auto"
                            />
                          </SpheraTooltip>
                        </div>
                      </div>
                    </fieldset>

                    <button ref={formSubmitRef} type="submit" hidden />
                  <button
                    ref={formResetRef}
                    type="reset"
                    onClick={() => {
                      formRenderProps.onFormReset();
                    }}
                    hidden
                  /> 
                  </SpheraFormElement>
                );
              }}
            />
          </div>
        </ModalBody>
        <ModalFooter>
          <SpheraButtonEx className='cancelBtn' text={props.localizations.Cancel} onClick={props.modalCancelClick} />
          <SpheraButtonEx primary text={props.localizations.Save} onClick={(e)=> {formSubmitRef?.current?.click();}} />
        </ModalFooter>
      </Modal>
    </>
  );
};
