import * as React from "react";
import { SpheraDropdownEx } from "@sphera-cloud/component-ui-reactjs";

export function DropdownCell(props) {
    const handleChange = (e) => {
        if (props.onChange) {
            props.onChange({
                dataIndex: props.dataIndex,
                dataItem: props.dataItem,
                field: props.field,
                syntheticEvent: e.syntheticEvent,
                value: e.target.value.localeId,
            });
        }
    };
    const { dataItem } = props;
    const field = props.field || "";
    const dataValue = dataItem[field] === null ? "" : dataItem[field];

    let cLanguages = props.languages;
    if (props.otherLanguages != null && props.otherLanguages.length > 0)
        cLanguages = props.languages.filter(x => props.otherLanguages.findIndex(y => y.localeId === x.localeId) === -1 || dataItem.localeId == x.localeId);
    return (
        <td>
            {dataItem.inEdit ? (
                <SpheraDropdownEx
                    options={cLanguages}
                    value={props.languages.find((x) => x.localeId === props.dataItem.localeId)}
                    textField="language"
                    dataItemKey="localeId"
                    onChange={handleChange}
                    width="230px"
                    filterable 
                />
            ) : dataValue == null ? (
                ""
                ) : props.languages.find((x) => x.localeId === dataValue).language }
        </td>
    );
};