import React, {  useEffect } from "react";
import { AppBar } from "@sphera-cloud/component-ui-reactjs";
import { LanguageDropdown } from "./LanguageDropDown";
import { apiRoutes } from "../../apiRoutes";
import { SpheraModal} from '@sphera-cloud/component-ui-reactjs';

export function NavMenu(props) {
  const [languages, setlanguages] = React.useState([]);

  const getData = () => {
    fetch(apiRoutes.documents.language.options, { method: "GET" }).then(
      (response) => {
        response.json().then((json) => {
          setlanguages(json);
        });
      }
    );
  };

  const handleChange = (event) => {
    props.changeLanguage(event.target.value);
  };

  useEffect(() => {
      if (languages.length === 0)
          getData();
  }, [props.languageValue]);

  return (
    <>
      <AppBar
        entitlementConfig={props.menuSettings}
        cobrandInitialState={"show"}
        showUserSkeleton={true}
      />

      {props.showPreferencesModal && (
        <SpheraModal
          className="preferences-modal"
          title={props.localizations.Preferences || "Preferences"}
          width={360}
          height={240}
          noFooter={true}
          onClose={props.togglePreferences}
        >
          <div className="preferences-body py-3">
          <LanguageDropdown
             changeLanguage={handleChange}
             languages={languages}
             languageValue={props.languageValue}
           ></LanguageDropdown>
          </div>
        </SpheraModal>
      )}

    </>
  );
}
