import React from 'react';
import { cookieHandler } from "../cookieHandler";

export function ColumnManager (columnsJson, CookieName) {
	const { setCookie, getCookie } = cookieHandler();
	const CookName = CookieName;

	let documentGridSettingsJSON = getCookie(CookName);
	let docGridSet = [];
	let defaultSettings = JSON.parse(JSON.stringify(columnsJson));
	if (documentGridSettingsJSON != null && documentGridSettingsJSON !== "") {
		docGridSet = JSON.parse(documentGridSettingsJSON);

		for (const [i,col] of defaultSettings.entries()) {
			let colMatch = docGridSet.find(x => x.field === col.field);
			if (colMatch != null) {
				col.show = colMatch.show;
				col.width = colMatch.width == 0 ? 150 : colMatch.width;
				col.displayIndex = colMatch.index;
			}
			else {
				col.width = 150;
				col.displayIndex = i;
			}
		}
		defaultSettings.sort(function (a, b) { return a.displayIndex - b.displayIndex })
	}
	else {
		for (var i = 0; i < columnsJson.length; i++) {
			docGridSet.push({ "field": columnsJson[i].field, "show": true, "index": i })
		}
	}
	const [gridSettings, setgridSettings] = React.useState(docGridSet);

	const columnResize = (e) => {
		let newGridSettings = gridSettings;
		for (const col of newGridSettings) {
			let column = e.columns.find(x => x.field === col.field);
			if (column != null)
				col.width = column.width;
		}
		setCookie(CookName, JSON.stringify(newGridSettings), 400);
		setgridSettings(newGridSettings);
	};

	const columnReorder = (e) => {
		let newGridSettings = gridSettings;
		for (const col of newGridSettings) {
			let column = e.columns.find(x => x.field === col.field);
			if (column != null)
				col.index = column.orderIndex;
		}
		setCookie(CookName, JSON.stringify(newGridSettings), 400);
		setgridSettings(newGridSettings);
	};

	const onColumnsSubmit = (columnsState) => {
		let newGridSettings = gridSettings;
		for (const col of newGridSettings) {
			let stateCol = columnsState.find(x => x.field === col.field);
			if (stateCol != null)
				col.show = stateCol.show;
		}
		setCookie(CookName, JSON.stringify(newGridSettings), 400);
		setgridSettings(newGridSettings);
		return columnsState;
	};

    return {
		columnResize,
		columnReorder,
		onColumnsSubmit,
		defaultSettings
    };
}