import React from 'react';
import { SpheraModal } from '@sphera-cloud/component-ui-reactjs';
import { Grid, GridColumn as Column } from "@progress/kendo-react-grid";

export function CustomColumnWidthsModal(props) {
    return (
        <div>
            {props.showWidthModal && (
                <SpheraModal className="CustomColumnWidthModal" title={props.localizations.SetColumnWidths} width="80%" height="90vh" onClose={props.toggleWidthsModal} noFooter={true} >
                    <br/>
                    {props.showMaterial &&
                        <Grid
                            className="sphera-grid"
                            style={{
                                height: 'calc(100% - 20px)',
                                width: '100%'
                            }}
                            data={[]}
                            resizable={true}
                            onColumnResize={(e) => {
                                let width = e.newWidth;
                                if (width === 0)
                                    width = e.columns[e.index].width;
                                if (width < 100) {
                                    width = 100;
                                    e.columns[e.index].width = 100;
                                }
                                let column = props.materialColumns.filter(x => e.columns[e.index].field === x.field)[0];
                                if (column != null)
                                    column.width = width;
                                props.setmaterialColumns(props.materialColumns);
                            }}
                            onDoubleClick={(e) => {
                                console.log(e);
                            }}
                        >
                            {props.materialColumns.map(
                                (column, idx) =>
                                    (column.alwaysShow || (column.selected || (column.customerColumns != null && column.customerColumns[0].selected))) && (
                                        <Column
                                            key={idx}
                                            field={column.field}
                                            width={column.width}
                                            minResizableWidth={100}
                                            title={column.title}
                                        />
                                    )
                            )}
                        </Grid>
                    }
                    {props.showDetails &&
                        <Grid
                            className="sphera-grid"
                            style={{
                                height: 'calc(100% - 20px)',
                                width: '100%'
                            }}
                            data={[]}
                            resizable={true}
                            onColumnResize={(e) => {
                                let width = e.newWidth;
                                if (width === 0)
                                    width = e.columns[e.index].width;
                                if (width < 100) {
                                    width = 100;
                                    e.columns[e.index].width = 100;
                                }
                                let column = props.documentColumns.filter(x => e.columns[e.index].field === x.field)[0];
                                if (column != null)
                                    column.width = width;
                                props.setdocumentColumns(props.documentColumns);
                            }}
                            onDoubleClick={(e) => {
                                console.log(e);
                            }}
                        >
                            {props.documentColumns.map(
                                (column, idx) =>
                                    (column.alwaysShow || (column.selected || (column.customerColumns != null && column.customerColumns[0].selected))) && (
                                        <Column
                                            key={idx}
                                            field={column.field}
                                            width={column.width}
                                            minResizableWidth={100}
                                            title={column.title}
                                        />
                                    )
                            )}
                        </Grid>
                    }
                </SpheraModal>
            )}
        </div>
    );
}