export const baseApiUrl = 'api/v1';
export const apiRoutes = {
    documents: {
        language: {
            options: `${baseApiUrl}/open/language/options`,
        },
        version: `${baseApiUrl}/open/version`,
        localizations: `${baseApiUrl}/documents/localizations`,
        name: `${baseApiUrl}/documents/name`,
        history: `${baseApiUrl}/documents/history`,
        patch: `${baseApiUrl}/documents`,
        delete: `${baseApiUrl}/documents/all`,
        deleteDoc: `${baseApiUrl}/documents/deleteDoc`,
        getPhraseSections: `${baseApiUrl}/documents/getPhraseSections`,
        languages: `${baseApiUrl}/documents/languages`
    },
    materials: {
        search: `${baseApiUrl}/materials/search`,
        get: (id, languages) => `${baseApiUrl}/materials?materialId=${id}&languageIds=${languages}`
    },
    users: {
        requiredData: 'api/v1/users/required-data',
        get: 'api/v1/users',
        getUser: 'api/v1/users/data',
        post: 'api/v1/users',
        patch: 'api/v1/users',
        roles: {
            sites: `${baseApiUrl}/users/roles/sites`
        },
        role: {
            add: `${baseApiUrl}/users/role`,
            delete: `${baseApiUrl}/users/role`,
            sites: `${baseApiUrl}/users/role/sites`,
        },
        settings: {
            save: `${baseApiUrl}/users/settings` 
        },
        customer: {
            get: `${baseApiUrl}/customers`,
            post: `${baseApiUrl}/customers`,
            getAdmin: `${baseApiUrl}/customers/getAdmin`
        }
    },
    sites: {
        delete: `${baseApiUrl}/sites`,
        post: `${baseApiUrl}/sites`,
        get: `${baseApiUrl}/sites`
    },
    tokens: {
        delete: (id) => `${baseApiUrl}/api-tokens/${id}`,
        post: `${baseApiUrl}/api-tokens`,
        put: (id) => `${baseApiUrl}/api-tokens/${id}`,
        get: `${baseApiUrl}/api-tokens`,
        regenerate: (id)=>  `${baseApiUrl}/api-tokens/${id}/regenerate`
    },
    publicSites: {
        post: `${baseApiUrl}/publicSites`,
        get: `${baseApiUrl}/publicSites`,
        delete: (id) => `${baseApiUrl}/publicSites/${id}`,
        put: (id) => `${baseApiUrl}/publicSites/${id}`,
        checkDuplicate: (path, siteId) => `${baseApiUrl}/publicSites/check-duplicate/${path}?siteId=${siteId}`,
        GetSiteLogo: (id) => `${baseApiUrl}/publicSites/GetSiteLogo?Id=${id}`
    },
    public: {
        search: (searchObject, type) => `${baseApiUrl}/public/search?searchPhrase=${searchObject}&phrase=${type}`
    },
    widgets: {
        post: `${baseApiUrl}/widgets`,
        get: `${baseApiUrl}/widgets`,
        getById: (id) => `${baseApiUrl}/widgets/${id}`,
        delete: (id) => `${baseApiUrl}/widgets/${id}`,
        put: (id) => `${baseApiUrl}/widgets/${id}`,
        generateScripts: (id) => `${baseApiUrl}/widgets/generateScripts/${id}`
    },
    config: {
        get: `${baseApiUrl}/open/config`,
    },
    helpconfig: {
        get: `${baseApiUrl}/open/helpconfig`,
    },
    column: {
        GetColumns: `${baseApiUrl}/column/GetColumns`,
        GetPortalColumns: (id) => `${baseApiUrl}/column/GetCustomerPortalColumns?PublicPortalId=${id}`
    }
};
