import React from 'react';
import { SpheraModal, SpheraRadioButtonGroup } from '@sphera-cloud/component-ui-reactjs';
import { SpheraSpinner } from "@sphera-cloud/component-ui-reactjs";


export function ChangeDocumentSensitivityModal(props) {
    const radioButtonData = [
        { label: props.localizations.InternalProprietary, value: '1' },
        { label: props.localizations.Public, value: '2' },
    ];

    const loadingPanel = (
        <div className="k-loading-mask">
            <span className="k-loading-text">{props.localizations.Loading}</span>
            <SpheraSpinner version={1} color="dark-1" />
        </div>
    );

    return (
        <div>
            {props.showSensitivityModal && (
                <SpheraModal className="ChangeDocumentSensitivityModal" title={props.localizations.ChangeSensitivity} width={400} height={300}
                    onClose={props.toggleSensitivity}
                    onCancel={props.toggleSensitivity}
                    cancelText={props.localizations.Cancel}
                    actionText={props.localizations.Save}
                    onAction={props.submitChangeDocumentSensitivity}
                >
                    <div className="ChangeDocumentSensitivityModalDiv" >
                        <div>{props.localizations.SelectSensitivity}</div>
                        <SpheraRadioButtonGroup data={radioButtonData} direction="vertical" value={props.changeSensitivityValue} onChange={(e) => props.setchangeSensitivityValue(e.value)} />
                        {props.modalPending && loadingPanel}
                    </div>
                </SpheraModal>
            )}
        </div>
    );
}