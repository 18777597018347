import * as React from "react";
import { SpheraInput } from "@sphera-cloud/component-ui-reactjs";

export function ColumnNameCell(props) {
    const handleChange = (e) => {
        if (props.onChange) {
            props.onChange({
                dataIndex: props.dataIndex,
                dataItem: props.dataItem,
                field: props.field,
                syntheticEvent: e.syntheticEvent,
                value: e.target.value,
            });
        }
    };
    const { dataItem } = props;
    const field = props.field || "";
    const dataValue = dataItem[field] === null ? "" : dataItem[field];
    return (
        <td>
            {dataItem.inEdit ? (
                <SpheraInput
                    key={dataItem.LocalizationId}
                    name="customcolumnName"
                    type="text"
                    placeholder=""
                    onBlur={handleChange}
                    value={dataValue}
                    style={{ height: "30px", width: "80% !important"}}
                />
            ) : dataValue == null ? (
                ""
            ) : dataValue}
        </td>
    );
};