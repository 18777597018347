import * as React from "react";
import { Button } from "@progress/kendo-react-buttons";
export function CommandCell(props) {
    const { dataItem } = props;
    const inEdit = dataItem[props.editField];
    const isNewItem = dataItem.isNew;
    const validRow = dataItem.localization && dataItem.localeId != 0;
    const onDeleteData = () => {
        props.remove(props.dataItem);
    };

    return (
        <td className="k-command-cell">
            <Button
                className={!validRow && isNewItem ? "hidden" : ""}
                themeColor={"primary"}
                onClick={() =>
                    inEdit
                        ? isNewItem
                            ? props.add(dataItem)
                            : props.update(dataItem)
                        : props.edit(dataItem)
                }
            >
                {inEdit ? (isNewItem ? "Add" : "Update") : "Edit"}
            </Button>
            <Button
                className={inEdit && !isNewItem ? "hidden" : ""}
                themeColor={"primary"}
                onClick={() =>
                    inEdit ? props.discard(dataItem) : onDeleteData()
                }
            >
                {inEdit ? (isNewItem ? "Discard" : "Cancel") : "Remove"}
            </Button>
        </td>
    );
};