import React from "react";
import { Layout } from "../components/Layout";
import { apiRoutes } from "../apiRoutes";
import useFetchWithMsal from "../hooks/useFetchWithMsal";
import { SpheraButtonEx, SpheraModal, SpheraCheckbox, SpheraSpinner } from "@sphera-cloud/component-ui-reactjs";
import { Card, CardHeader, CardTitle, CardBody, CardActions, CardSubtitle, } from "@progress/kendo-react-layout";
import "./Distribution.css";
import { TokensToolbar } from "./components/TokensToolbar";
import { ConfirmDeleteTokenDialog } from "./components/ConfirmDeleteTokenDialog";
import { EditTokenForm } from "./components/EditTokenForm";
import { useNavigate } from "react-router-dom";

export const Distribution = (props) => {
  const [pending, setPending] = React.useState(false);
  const [error, seterror] = React.useState(false);
  const [errorMessage, seterrorMessage] = React.useState("");
  const [apiTokens, setApiTokens] = React.useState([]);
  const [currentToken, setCurrentToken] = React.useState({});
  const [visible, setVisible] = React.useState(false);
  const [editableCards, setEditableCards] = React.useState([]);
  const [currentCard, setCurrentCard] = React.useState();
  const [isData, setIsData] = React.useState(false);
  const [isNew, setIsNew] = React.useState(false);
  const [showConfirmDelete, setShowConfirmDelete] = React.useState(false);

  const createTokenHandler = (event) => {
    const newEditableCards = [...editableCards];
    
    if (isNew) {
     const isAlreadyAdding = newEditableCards.filter(card=> {
         return card.isEdit;
     });
     if (isAlreadyAdding.length>=1) newEditableCards.splice(0, 1);
    }
    

    newEditableCards.unshift({ isEdit: true, apiToken: { name: "" } });
    setEditableCards(newEditableCards);
    setIsData(true);
    setIsNew(true);
  };

  let navigate = useNavigate();

  const toggleConfirmDeleteDialog = (event) => {
    setShowConfirmDelete(!showConfirmDelete);
  };

  const deleteToken = (card) => {
    setPending(true);
    execute("DELETE", apiRoutes.tokens.delete(card.apiToken.id)).then(
      (json) => {
        setPending(false);
        toggleConfirmDeleteDialog();
        setPending(true);
        execute("GET", apiRoutes.tokens.get).then((json) => {
          if (json !== null) {
            setApiTokens(json);
            setPending(false);
            setIsData(true);
            const editableCards = [];

            json?.forEach((apiToken, key) =>
              editableCards.push({ isEdit: false, apiToken: apiToken })
            );
            setEditableCards(editableCards);
          }
        });
      }
    );
  };

  const toggleEdit = (card, index) => {
    setIsNew(false);
    const currentEditableIndex = editableCards.findIndex(
      (c) => c.apiToken.id === card.apiToken.id
    );
    const updatedEditable = { ...editableCards[currentEditableIndex] };
    updatedEditable.isEdit = !updatedEditable.isEdit;

    // all other cards not edited
    editableCards.forEach((c) => {
      c.isEdit = false;
    });
    setEditableCards(editableCards);

    const newEditables = [
      ...editableCards.slice(0, currentEditableIndex),
      updatedEditable,
      ...editableCards.slice(currentEditableIndex + 1),
    ];

    setEditableCards(newEditables);
  };

    const { execute, result } = useFetchWithMsal({
    scopes: props.loginConfig.protectedResources.apiDocumentList.scopes.read,
  });

  const getData = () => {
    setPending(true);
    execute("GET", apiRoutes.tokens.get)
      .then((json) => {
        if (json && json.length !== 0) {
          setApiTokens(json);
          setPending(false);
          setIsData(true);
          const editableCards = [];

          json?.forEach((apiToken, key) =>
            editableCards.push({ isEdit: false, apiToken: apiToken })
          );
          setEditableCards(editableCards);
        } else {
          setIsData(false);
          setPending(false);
        }
      })
      .catch((err) => {
        seterror(true);
        seterrorMessage("");
      })
      .finally(() => {
        setPending(false);
        setTimeout(()=> {
          seterror(false);
        }, 5000);
      });
  };

    const [gotData, setgotData] = React.useState(false);
    React.useEffect(() => {
        if (gotData || result == null)
            return;
        if (props.currentTenent.CustomerId != null && !props.currentTenent.IsEditCustomerPortalUser)
            navigate("/");
        setgotData(true);
    }, [execute]);

    React.useEffect(() => {
        if (gotData)
            getData();
    }, [gotData]);

  return (
    <>
      <Layout
        versionNumber={props.versionNumber} 
        currentTenent={props.currentTenent}
        localizations={props.localizations}
        changeLanguage={props.changeLanguage}
        languageValue={props.languageValue}
        error={error}
        setError={seterror}
        errorMessage={errorMessage}
      >
        <br />
        <TokensToolbar
          localizations={props.localizations}
          createTokenHandler={createTokenHandler}
          editableCards={editableCards}
          isNew={isNew}
        ></TokensToolbar>
        <div
          style={{
            display: "flex",
            justifyContent: !isData ? "center" : "initial",
            flexWrap: "wrap",
            gap: "2%",
          }}
        >
          {isData &&
            editableCards.map((card, i) => {
              return (
                <Card
                  key={i}
                  style={{
                    width: 320,
                    boxShadow: "0 0 4px 0 rgba(0, 0, 0, .1)",
                    marginTop: "15px",
                    border: card.isEdit ? "1px solid #F5821F" : "none",
                    background: card.isEdit ? "#E2E5EA" : "none",
                  }}
                >
                  <CardHeader
                    className="k-hbox"
                    style={{
                      background: "transparent",
                    }}
                  >
                    <div>
                      <CardTitle
                        style={{
                          marginBottom: "4px",
                        }}
                      >
                        {card.apiToken.name}
                      </CardTitle>
                      <CardSubtitle>
                        <p style={{ wordBreak: 'break-word'}}>{card.apiToken.description}</p>
                      </CardSubtitle>
                    </div>
                  </CardHeader>
                  <CardBody>
                    {!card.isEdit && (
                      <div>
                        <span>
                          <b>{props.localizations.ExpiredAt}: </b>

                          {card.apiToken.expiredAt &&
                            new Date(card.apiToken.expiredAt)
                              .toISOString()
                              .slice(0, 19)
                              .replace(/-/g, "/")
                              .replace("T", " ")}

                          {!card.apiToken.expiredAt && (
                            <img
                              src={require("../images/infinity.svg").default}
                              alt="Never"
                              width="24"
                              style={{ fontWeight: "bold" }}
                            />
                          )}
                        </span>
                        <br />
                        <span>
                          <b>{props.localizations.GeneratedAt}: </b>
                          {card.apiToken.generatedAt &&
                            new Date(card.apiToken.generatedAt)
                              .toISOString()
                              .slice(0, 19)
                              .replace(/-/g, "/")
                              .replace("T", " ")}
                        </span>
                        <br />
                      </div>
                    )}
                    {card.isEdit && (
                      <EditTokenForm
                        loginConfig={props.loginConfig}
                        card={card}
                        localizations={props.localizations}
                        toggleEdit={toggleEdit}
                        setApiTokens={setApiTokens}
                        setEditableCards={setEditableCards}
                        editableCards={editableCards}
                        setIsData={setIsData}
                        setError={seterror}
                        isNew={isNew}
                        setIsNew={setIsNew}
                        setPending={setPending}
                      ></EditTokenForm>
                    )}
                  </CardBody>
                  <CardActions className="border-top row col-12 mx-auto">
                    {!card.isEdit && (
                      <>
                        <div className="col-6 p-0 float-left">
                          <SpheraButtonEx
                            text={props.localizations.Delete}
                            secondary={true}
                            className="float-start btn-secondary"
                            onClick={(event) => {
                              toggleConfirmDeleteDialog(event);
                              setCurrentCard(card);
                            }}
                          />
                        </div>
                        <div className="col-5">
                          <SpheraButtonEx
                            text={props.localizations.Edit}
                            primary={true}
                            onClick={(event) => {
                              toggleEdit(card);
                            }}
                            className="btn-primary float-end"
                          />
                        </div>
                      </>
                    )}
                  </CardActions>
                </Card>
              );
            })}
          {!isData && !pending && (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                flexWrap: "wrap",
                marginTop: "4rem",
              }}
            >
              <div className="icon-item text-center">
                <i className="icon-outline-search"></i>
                <div
                  className="font-scale-24pt"
                  style={{ width: "120px", textTransform: "capitalize" }}
                >
                  {props.localizations.NoData}
                </div>
              </div>
            </div>
          )}
          
            { pending && (
                <div className="k-loading-mask general">
                    <SpheraSpinner version={1} color="dark-1" />
                </div>
            )}
        </div>

        {visible && (
          <SpheraModal
            title={props.localizations.ApiToken}
            wide={true}
            cancelText={props.localizations.Copy}
            actionText={props.localizations.Close}
            onClose={() => {
              setVisible(false);
            }}
          >
            <p
              style={{
                padding: "1rem",
                marginRight: "auto",
                marginLeft: "auto",
              }}
            >
              <div
                style={{
                  backgroundColor: "#0877A7",
                  color: "white",
                  width: "400px",
                  padding: "1rem",
                  marginRight: "auto",
                  marginLeft: "auto",
                }}
              >
                {props.localizations.CopyTokenMessage}
              </div>
              <span>
                <b>{props.localizations.Token}:</b>
              </span>{" "}
              <br />
              <span style={{ overflowWrap: "break-word" }}>
                 {currentToken.token} 
              </span>
            </p>
          </SpheraModal>
        )}
      </Layout>
      {showConfirmDelete && (
        <ConfirmDeleteTokenDialog
          card={currentCard}
          toggleConfirmDeleteDialog={toggleConfirmDeleteDialog}
          deleteToken={deleteToken}
          localizations={props.localizations}
        ></ConfirmDeleteTokenDialog>
      )}
    </>
  );
};
