import React from 'react';
import { SpheraModal } from '@sphera-cloud/component-ui-reactjs';
import { SpheraSpinner } from "@sphera-cloud/component-ui-reactjs";


export function DeleteDocumentsModal(props) {
    const loadingPanel = (
        <div className="k-loading-mask">
            <span className="k-loading-text">{props.localizations.Loading}</span>
            <SpheraSpinner version={1} color="dark-1" />
        </div>
    );

    return (
        <div>
            {props.showDeleteDocumentsModal && (
                <SpheraModal className="DeleteDocumentsModal" title={props.localizations.DeleteDocuments} width={400} height={300}
                    onClose={props.toggleDeleteDocuments}
                    onCancel={props.toggleDeleteDocuments}
                    cancelText={props.localizations.Cancel}
                    actionText={props.localizations.Save}
                    onAction={props.submitDeleteDocuments}
                >
                    <div className="DeleteDocumentsModalDiv" >
                        <div>{props.localizations.DeleteDocumentsConfirm}</div>
                        {props.modalPending && loadingPanel}
                    </div>
                </SpheraModal>
            )}
        </div>
    );
}