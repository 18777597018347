import timezones from "../Data/timezones.json";

export function DateFormater() {
    const adjustForTimezone = (dateString, timezoneId) =>
    {
        let utc = new Date(dateString);
        if (timezoneId != null) {
            let timezone = timezones.find(x => x.timezoneId === timezoneId);
            if (timezone != null) {
                utc = utc.setHours(utc.getHours() + timezone.offset);
                utc = new Date(utc);
            }
        }
        return utc;
    }

    const formatDate = (utc, dateFormatId, includeTime) => {
        let timeString = utc.toLocaleTimeString();
        let returnDateString = utc.toLocaleDateString();

        if (dateFormatId != null) {
            switch (dateFormatId) {
                case 1:
                    returnDateString = utc.getDate() + "/" + (utc.getMonth() + 1) + "/" + utc.getFullYear();
                    break;
                case 2:
                    returnDateString = (utc.getMonth() + 1) + "/" + utc.getDate() + "/" + utc.getFullYear();
                    break;
                case 3:
                    returnDateString = utc.getFullYear() + "/" + (utc.getMonth() + 1) + "/" + utc.getDate();
                    break;
                default:
                    break;
            }
        }

        if (includeTime)
            return returnDateString + " " + timeString;
        else
            return returnDateString;
	};

    return { adjustForTimezone, formatDate };
}