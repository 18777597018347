import React from "react";
import { SpheraDropdownEx } from "@sphera-cloud/component-ui-reactjs";

export const LanguageDropdown = (props) => {
  return (
    <React.Fragment>
      <div className="sch-help-button-container mx-4">
        <i
          style={{
            fontSize: "32px",
            background: "white",
            marginTop: "4px",
            marginRight:'-2px',
            borderTopLeftRadius: "2px",
            borderBottomLeftRadius: "2px",
            border: "1px solid rgb(198, 204, 214)",
          }}
          className="k-icon k-i-globe-outline"
        ></i>
        <SpheraDropdownEx
          options={props.languages}
          value={props.languageValue}
          textField="language"
          dataItemKey="localeId"
          onChange={props.changeLanguage}
          width="270px"
          filterable
          
        />
      </div>
    </React.Fragment>
  );
};
