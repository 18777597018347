import React from 'react';
import { SpheraModal } from '@sphera-cloud/component-ui-reactjs';
import { Grid, GridColumn as Column, GridToolbar } from "@progress/kendo-react-grid";
import { SpheraButtonEx, SpheraTooltip } from "@sphera-cloud/component-ui-reactjs";
import CustomColumnLocalizationGridColumns from "../Data/CustomColumnLocalizationGridColumns.json";
import { ColumnManager } from "./columnManager";
import { CommandCell } from "./CommandCell";
import { DropdownCell } from "./DropdownCell";
import { ColumnNameCell } from "./ColumnNameCell";

export function CustomColumnLocalizationModal(props) {
    const editField = "inEdit";
    const GridContext = React.createContext({});
    const CommandCellConst = (props) => {
        const { enterEdit, remove, add, discard, update, cancel, editField } =
            React.useContext(GridContext);
        return (
            <CommandCell
                {...props}
                edit={enterEdit}
                remove={remove}
                add={add}
                discard={discard}
                update={update}
                cancel={cancel}
                editField={editField}
            />
        );
    };

    const { columnResize, columnReorder } = ColumnManager(CustomColumnLocalizationGridColumns, "CustomColumnGridSettings");

    return (
        <div>
            {props.showCustomColumnModal && (
                <SpheraModal className="MaterialSynoymsModal" title={props.localizations.CustomColumnLocalizations} width={700} height="440px" onClose={props.toggleCustomColumn} noFooter={true} >
                    <div >
                        <GridContext.Provider
                            value={{
                                enterEdit: props.enterEdit,
                                remove: props.remove,
                                add: props.add,
                                discard: props.discard,
                                update: props.update,
                                cancel: props.cancel,
                                editField,
                            }}
                        >
                        <Grid
                            className="sphera-grid"
                            style={{
                                height: '330px',
                                width: '95%',
                                marginLeft: "auto",
                                marginRight: "auto",
                                marginTop: "10px",
                                maxHeight: '380px'
                                }}
                                onItemChange={props.itemChange}

                                editField={editField}
                                data={props.dataResult}
                                {...props.dataState}
                                onDataStateChange={props.dataStateChange}
                            sortable={true}
                            reorderable={true}
                            resizable={true}
                            scrollable="scrollable"
                            onColumnReorder={columnReorder}
                            onColumnResize={columnResize}
                            pageable={{
                                buttonCount: 4,
                                pageSizes: [25, 50, 100]
                            }}
                        >
                            <GridToolbar>
                                <SpheraTooltip anchor="target" position="top">
                                        <SpheraButtonEx style={{ height: '32px' }} primary text={props.localizations.AddLocalization} onClick={() => props.addNew()} />
                                </SpheraTooltip>
                            </GridToolbar>
                                <Column
                                    cell={(props2) => <ColumnNameCell {...props2} languages={props.languages} />}
                                    field="localization"
                                    minResizableWidth={100}
                                    title={props.localizations.Localization}
                                    filter="text"
                                />
                                <Column
                                    cell={(props2) => <DropdownCell {...props2} languages={props.languages} otherLanguages={props.selectedColumn.customerColumns[0].columnLocalizations} />}
                                    field="localeId"
                                    minResizableWidth={100}
                                    title={props.localizations.Language}
                                    filter="text"
                                />
                                <Column cell={CommandCellConst} width="150px"  minResizableWidth={100} filterable={false} />
                            </Grid>

                        </GridContext.Provider>
                    </div>
                </SpheraModal>
            )}
        </div>
    );
}