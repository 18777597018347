import React from 'react';

export function ColumnSelectButton(props) {
    const [columns, setColumns] = React.useState(props.columns);
    const container = React.createRef();

    const handleClickOutside = (event) => {
        if (container.current && !container.current.contains(event.target))
            props.setopen(false);
    };

    React.useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside);
    }, [container]);

    const onToggleColumn = id => {
        const newColumns = columns.map((column, idx) => {
            return idx === id ? {
                ...column,
                show: !column.show
            } : column;
        });
        setColumns(newColumns);
    };
    const onReset = event => {
        event.preventDefault();
        const newColumns = props.columns.map(col => {
            return {
                ...col,
                show: props.originalColumns.find(x => x.field === col.field).show
            };
        });
        setColumns(newColumns);
        props.onColumnsSubmit(newColumns);

        props.setopen(false);
    };
    const onSubmit = event => {
        if (event) {
            event.preventDefault();
        }
        props.onColumnsSubmit(columns);

        props.setopen(false);
    };

    const oneVisibleColumn = columns.filter(c => c.show).length === 1;

    return (
        <div ref={container} className="columnButtonContainer" >
            {props.open && (<div className={'k-column-list-wrapper columnDropdown'}>
                <div className={'k-column-list'}>
                    {columns.map((column, idx) => !column.alwaysShow && (<div key={idx} className={'k-column-list-item'}>
                        <span>
                            <input id={`column-visiblity-show-${idx}`} 
                                className="k-checkbox k-checkbox-md k-rounded-md"
                                type="checkbox"
                                readOnly={true} 
                                disabled={column.show && oneVisibleColumn} 
                                checked={column.show} 
                                onClick={() => {
                                    onToggleColumn(idx);
                                }}
                             />
                            <label htmlFor={`column-visiblity-show-${idx}`} className="k-checkbox-label" style={{
                                userSelect: 'none'
                            }}>
                                {props.localizations[column.title?.replace(' ', '')]}
                            </label>
                        </span>
                    </div>))}
                </div>
                <div className={'k-actions k-hstack k-justify-content-stretch'}>
                    <button type={'reset'} className={'k-button k-button-md k-rounded-md k-button-solid k-button-solid-base'} onClick={onReset} >{props.localizations.Reset}</button>
                    <button className={'k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary'} onClick={onSubmit} >{props.localizations.Save}</button>
                </div>
            </div>)}
        </div>
    );
}


