import React from "react";
import { Toolbar, ToolbarSpacer } from "@progress/kendo-react-buttons";
import { SpheraButtonEx, SpheraButtonGroup } from "@sphera-cloud/component-ui-reactjs";

export const TokensToolbar = (props) => {
  
  return (
    <Toolbar>
      <ToolbarSpacer />
      <SpheraButtonGroup>
        <SpheraButtonEx
          text={props.localizations.AddToken}
          onClick={props.createTokenHandler}
        />
      </SpheraButtonGroup>
    </Toolbar>
  );
};
