import { createContext, useReducer } from "react";

const WidgetContext = createContext({
  items: [],

  error: null,
  status: 'idle',

  currentCard: {},
  currentWidget: {},
  addItem: (item) => {},

  loadItems: (items) => {},
  loadItemsRejected: (items) => {},
  loadItemsPending: () => {},

  removeItem: (id) => {},
  setCurrent: (item) => {},
  loadData: (data) => {},
  loadDataRejected: (data) => {},
  loadDataFetching: (data) => {},
  localizations: [],

  loadCurrentWidget: (data) => {},
  generateScripts: (widgetId) => {}
});

const widgetReducer = (state, action) => {
  if (action.type === "ADD_ITEM") {
    const existingCartItemIndex = state.items.findIndex(
      (item) => item.id === action.item.id
    );

    const updatedItems = [...state.items];

    if (existingCartItemIndex > -1) {
      const existingItem = state.items[existingCartItemIndex];
      const updatedItem = {
        ...existingItem
      };
      updatedItems[existingCartItemIndex] = updatedItem;
    } else {
      updatedItems.push({ ...action.item});
    }

    return { ...state, items: updatedItems };
  }

  if (action.type === "REMOVE_ITEM") {
    const existingCartItemIndex = state.items.findIndex(
      (item) => item.id == action.id
    );
    const existingCartItem = state.items[existingCartItemIndex];

    const updatedItems = [...state.items];
    updatedItems.splice(existingCartItemIndex, 1);
    return { ...state, items: updatedItems };
  }

  if (action.type === "SET_CURRENT") {
    debugger;
    const existingCartItemIndex = state.items.findIndex(
      (item) => item.id === action.item.id
    );
    const existingCartItem = state.items[existingCartItemIndex];

    return { ...state, currentCard: existingCartItem };
  }

  if (action.type === "LOAD_DATA") {
    return { ...state, localizations:  []};
  }


  if (action.type === "LOAD_ITEMS_FETCHING") {
    return { ...state, status: 'pending'};
  }

  if (action.type === "LOAD_ITEMS") {
    return { ...state,
       status: 'success',
       items: action.response || [],
       error: null
    };
  }

  if (action.type === "LOAD_ITEMS_ERROR") {
    return { ...state,
       status: 'failed',
       items: [],
       error: action.err
    };
  }

  if (action.type === "LOAD_CURRENT_WIDGET") {
    return { 
      ...state,
      currentWidget: action.data
    };
  }

  return state;
};

export function WidgetContextProvider({ children }) {
  const [widgets, dispatchWidgetAction] = useReducer(widgetReducer, {
    items: [],
    currentCard: {},
    currentWidget: {},
    localizations: []
  });

  function addItem(item) {
    dispatchWidgetAction({ type: "ADD_ITEM", item });
  }

  function removeItem(id) {
    dispatchWidgetAction({ type: "REMOVE_ITEM", id });
  }

  function setCurrent(item) {
    dispatchWidgetAction({ type: "SET_CURRENT", item });
  }

  function loadData(data) {
    dispatchWidgetAction({ type: "LOAD_DATA",  data});
  }


  function loadItems (response) {
    dispatchWidgetAction({ type: "LOAD_ITEMS",  response});
  };

  function loadItemsRejected (err)  {
    dispatchWidgetAction({ type: "LOAD_ITEMS_ERROR",  err});
  };

  function loadItemsPending (data) {
    dispatchWidgetAction({ type: "LOAD_ITEMS_FETCHING",  data});
  };  

  function loadCurrentWidget(data) {
    dispatchWidgetAction({ type: "LOAD_CURRENT_WIDGET", data });
  };
 
  function generateScripts(data) {
    dispatchWidgetAction({ type: "GENERATE_SCRIPTS", data });
  }

  
  const widgetContext = {
    items: widgets.items,
    currentCard: widgets.currentCard,
    localizations: widgets.localizations,

    currentWidget: widgets.currentWidget,
    loadCurrentWidget,
    addItem,
    removeItem,
    setCurrent,
    loadData,

    loadItems,
    loadItemsRejected,
    loadItemsPending
  };

  return (
    <WidgetContext.Provider value={widgetContext}>
      {children}
    </WidgetContext.Provider>
  );
}

export default WidgetContext;
