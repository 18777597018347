import React from 'react';
import { Layout } from './Layout';
import { process } from "@progress/kendo-data-query";
import { CustomersGrid } from './CustomersGrid';
import useFetchWithMsal from '../hooks/useFetchWithMsal';
import { apiRoutes } from '../apiRoutes';
import { useNavigate } from "react-router-dom";
import { Menu, MenuItem } from "@progress/kendo-react-layout";
import { Popup } from "@progress/kendo-react-popup";
import { SpheraModal, SpheraCheckbox } from '@sphera-cloud/component-ui-reactjs';

export function ManageCustomers(props) {
	let navigate = useNavigate();

	const [downloadGrid, setdownloadGrid] = React.useState(false);
	const [showColumnSelect, setshowColumnSelect] = React.useState(false);
	const [buttonsConfig, setbuttonsConfig] = React.useState([]);

	const [customers, setcustomers] = React.useState([]);
	const [originalCustomers, setoriginalCustomers] = React.useState([]);
	const [success, setsuccess] = React.useState(false);
	const [error, seterror] = React.useState(false);
	const [errorMessage, seterrorMessage] = React.useState("");
	const [dataState, setDataState] = React.useState({ skip: 0, take: 25 });
	const [pending, setPending] = React.useState(false);

	const [currentCustomerId, setcurrentCustomerId] = React.useState('');
	const [showClearDocumentsModal, setshowClearDocumentsModal] = React.useState(false);
	const [showSyncSCMUsersModal, setshowSyncSCMUsersModal] = React.useState(false);
	const [scmInserts, setscmInserts] = React.useState(true);
	const [dsInserts, setdsInserts] = React.useState(true);
	const [elasticOnly, setelasticOnly] = React.useState(true);
	const [showAll, setshowAll] = React.useState(false);

	const offSet = React.useRef({
		left: 0,
		top: 0,
	});
	const [show, setShow] = React.useState(false);
	const handleContextMenu = (e) => {
		e.preventDefault();
		offSet.current = {
			left: e.clientX,
			top: e.clientY,
		};
		setShow(true);
	};

	const [dataResult, setDataResult] = React.useState(
		process(customers, dataState)
	);
	const dataStateChange = (event) => {
		setDataResult(process(customers, event.dataState));
		setDataState(event.dataState);
	};

	const { execute } = useFetchWithMsal({
		scopes: props.loginConfig.protectedResources.apiDocumentList.scopes.read,
	});

	const toggleClearDocuments = () => setshowClearDocumentsModal(false);
	const reloadDocumentsClick = (e) => {
		if (e.item.text === 'Delete Documents')
			setshowClearDocumentsModal(true);
		else
			setshowSyncSCMUsersModal(true);
	}
	const toggleSyncSCMUsers = () => setshowSyncSCMUsersModal(false);

	const editCustomerClick = (customerId, e, MenuClick) => {
		setcurrentCustomerId(customerId);
		handleContextMenu(e);
	};

	const clearDocuments = () => {
		setshowClearDocumentsModal(false);
		setPending(true);
		execute("DELETE", apiRoutes.documents.delete + "?spheraCustomerId=" + currentCustomerId + "&elasticOnly=" + elasticOnly)
			.then(json => {
				if (json != null) {
					setPending(false);
				}
			});
	};

	const SyncSCMUsers = () => {
		setshowSyncSCMUsersModal(false);
		setPending(true);
		execute("PATCH", apiRoutes.users.patch + "?CustomerId=" + currentCustomerId + "&scmInserts=" + scmInserts + "&dsInserts=" + dsInserts)
			.then(json => {
				if (json != null) {
					setPending(false);
				}
			});
	};

	const getData = (execute) => {
		setPending(true);
		execute("GET", apiRoutes.users.customer.get)
			.then(json => {
				if (json != null) {
					let filteredUsers = json.filter(x => x.statusId === 1);

					setcustomers(filteredUsers);
					setoriginalCustomers(json);
					setDataResult(process(filteredUsers, dataState));
					setPending(false);
				}
			});
	}

	const handleScmInsertsChange = (e) => setscmInserts(e.value);
	const handleDsInsertsChange = (e) => setdsInserts(e.value);
	const handleElasticOnlyChange = (e) => setelasticOnly(e.value);

	React.useEffect(() => { getData(execute); }, [execute]);

	React.useEffect(() => {
		if (props.localizations.IncludeActive != null) {
			setbuttonsConfig([
				{
					type: 'secondary',
					icon: 'icon-ui-general-libraries',
					tooltip: props.localizations.Columns,
					position: "top",
					onClick: () => { setshowColumnSelect(true); }
				},
				{
					type: 'secondary',
					icon: 'icon-ui-general-download',
					tooltip: props.localizations.Download,
					position: "top",
					onClick: () => { setdownloadGrid(true); }
				}
			]);

		}
		if (props.currentTenent.CustomerId != null && !props.currentTenent.IsInternalSCUserManagementAdmin)
			navigate("/");
	}, [props.localizations]);

	React.useEffect(() => {
		document.addEventListener("click", (e) => {
			if (show && e.target.className !== 'icon-ui-general-three-dots-vertical')
				setShow(false)
		});
	}, [show]);

	const handleShowAllUsersChange = () => {
		setshowAll(!showAll);

		if (showAll) {
			let filteredUsers = originalCustomers.filter(x => x.statusId === 1);
			setcustomers(filteredUsers);
			setDataResult(process(filteredUsers, dataState));
		}
		else {
			setcustomers(originalCustomers);
			setDataResult(process(originalCustomers, dataState));
		}
	}

	return (
		<Layout versionNumber={props.versionNumber} buttonsConfig={buttonsConfig} currentTenent={props.currentTenent} success={success} setSuccess={setsuccess} error={error} setError={seterror} errorMessage={errorMessage} localizations={props.localizations} changeLanguage={props.changeLanguage} languageValue={props.languageValue} >
			<div>
				<CustomersGrid dateFormatId={props.customer.dateFormatId} localizations={props.localizations} pending={pending} editCustomerClick={editCustomerClick}
					customers={customers} dataState={dataState} dataResult={dataResult} dataStateChange={dataStateChange} handleShowAllUsersChange={handleShowAllUsersChange}
					showColumnSelect={showColumnSelect} downloadGrid={downloadGrid} setdownloadGrid={setdownloadGrid} setshowColumnSelect={setshowColumnSelect}/>
			</div>
			<Popup show={show} offset={offSet.current}>
				<Menu
					vertical={true}
					style={{
						display: "inline-block",
					}}
					onSelect={reloadDocumentsClick}
				>
					<MenuItem text="Delete Documents" />
				</Menu>
			</Popup>
			{ showClearDocumentsModal &&
				<SpheraModal className="ClearDocumentsModal" title="Delete Documents" width={400} height={300}
					onClose={toggleClearDocuments}
					onCancel={toggleClearDocuments}
					cancelText={props.localizations.Cancel}
					actionText={props.localizations.Delete}
					onAction={clearDocuments}
				>
					<div className="ClearDocumentsModalDiv" >
						<span style={{ height: "47px", display: "inline-block" }}>
							<i className="icon-ui-general-warning" style={{ background: "red", width: "47px", height: "47px" }}></i>
						</span>
						<span className="clearDocumentsText" >Are you sure you want to remove ALL DOCUMENTS from the customer?</span>
						<br />
						<span className="clearDocumentsTextCustomerId" style={{ marginLeft: "130px", fontSize: "25px", fontWeight: "bolder", color: "red"}}>{currentCustomerId}</span>
						<br />
						<div>
							<SpheraCheckbox label="Elastic Only" primary={true} value={elasticOnly} onChange={handleElasticOnlyChange} />
						</div>
					</div>
				</SpheraModal>
			}
			{showSyncSCMUsersModal &&
				<SpheraModal className="SyncSCMUsersModal" title="Sync SCM Users" width={400} height={300}
					onClose={toggleSyncSCMUsers}
					onCancel={toggleSyncSCMUsers}
					cancelText={props.localizations.Cancel}
					actionText={props.localizations.Save}
					onAction={SyncSCMUsers}
				>
					<div className="SyncSCMUsersModalDiv" >
						<div>
							<SpheraCheckbox label="SCM Inserts" primary={true} value={scmInserts} onChange={handleScmInsertsChange} />
						</div>
						<br/>
						<div>
							<SpheraCheckbox label="DS Inserts" primary={true} value={dsInserts} onChange={handleDsInsertsChange} />
						</div>
					</div>
				</SpheraModal>
			}
		</Layout>
	);
}
