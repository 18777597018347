import React from 'react';
import { Grid, GridColumn as Column, GridColumnMenuWrapper } from "@progress/kendo-react-grid";
import { LoadingPanel } from './LoadingPanel';
import { TextCell } from './TextCell';
import dateFormats from "../Data/dateFormats.json";
import { CustomColumnMenu } from "./CustomColumnMenu";
import { useNavigate } from "react-router-dom";
import { GridColumnMenuFilter } from '@progress/kendo-react-grid';
import { SpheraTooltip } from "@sphera-cloud/component-ui-reactjs";

export function DocumentGrid(props) {
    const navigate = useNavigate();
    const searchedPhrase = props.searchedPhrase;
    let dateFormat = "{0:dd/MM/yyyy}";
    let dateFormatFilter = "dd/MM/yyyy";
    if (props.dateFormatId != null) {
        let format = dateFormats.find(x => x.value === props.dateFormatId);
        if (format != null) {
            dateFormat = "{0:" + format.text + "}";
            dateFormatFilter = format.text;
        }
    }

    const linkClick = (materialId) => {
        localStorage.removeItem("sphdsSearchPhrase");
        localStorage.setItem("sphdsSearchPhrase", searchedPhrase);
        navigate("/material/" + materialId + "?fromSearch=true");
    }

    return (
        <div style={{ height: 'calc(100vh - 325px)', minHeight: '300px' }} className={!(props.currentTenent.RequiresNavMenu) ? 'gridAddMargin' : ''}>
            <Grid
                className="sphera-grid"
                style={{
                    height: '100%',
                    width: '100%'
                }}
                data={props.dataResult}
                {...props.dataState}
                onDataStateChange={props.dataStateChange}
                sortable={true}
                resizable={true}
                scrollable="scrollable"
                pageable={{
                    buttonCount: 4,
                    pageSizes: [25, 50, 100]
                }}
            >
                {props.materialColumns.map(
                    (column, idx) =>
                        column.show && (
                            <Column
                                key={idx}
                                cell={ (props2) =>
                                    <TextCell field={column.field}
                                        isLink={column.IsLink}
                                        searchedPhrase={searchedPhrase}
                                        linkClick={linkClick}
                                        clickId={props2.dataItem.materialId}
                                        DisplayValue={column.AdjustTimezone ? props2.dataItem[column.field + "Display"] : props2.dataItem[column.field]}
                                        customer={props.customer}
                                        isDate={column.IsDate}
                                        adjustTimezone={column.AdjustTimezone}
                                    />
                                }
                                field={column.field}
                                width={column.width}
                                minResizableWidth={100}
                                title={column.Title}
                                filter={column.Filter}
                                format={dateFormat}
                                headerCell={(props2) => 
                                    <span className="k-cell-inner">
                                        <span className="k-link" onClick={props2.onClick} >
                                            <SpheraTooltip anchor="target" position="top" title={column.Title} >
                                                <span className="k-column-title">{column.Title}</span>
                                            </SpheraTooltip>
                                            {props2.children}
                                        </span>
                                        <GridColumnMenuWrapper
                                            {...props2.columnMenuWrapperProps}
                                        ></GridColumnMenuWrapper>
                                    </span>
                                }
                                headerClassName={GridColumnMenuFilter.active(column.field, props.dataState.filter) ? 'active' : ''}
                                columnMenu={(props) => (
                                    <CustomColumnMenu {...props} columns={props.materialColumns} useDateFilter={column.IsDate} dateFormatFilter={dateFormatFilter} />
                                )}
                            />
                        )
                )}
            </Grid>
            {props.pending && <LoadingPanel localizations={props.localizations} />}
        </div>
    );
}