import React from 'react';
import { Layout } from './Layout';
import { LoadingPanel } from './LoadingPanel';
import useFetchWithMsal from '../hooks/useFetchWithMsal';
import { SpheraButtonEx } from "@sphera-cloud/component-ui-reactjs";
import countryCodes from "../Data/countryCodes.json";
import { apiRoutes } from '../apiRoutes';
import { useNavigate } from "react-router-dom";
import { UserForm } from './UserForm';

export function ManageUser(props) {
	const usertypes = {
		SpheraAdmin: 1,
		TenentAdmin: 2,
		Tenent: 3
	};
	const navigate = useNavigate();
	const hrefParts = window.location.href.split("/");
	const userId = hrefParts[hrefParts.length - 1];
	const [userRoles, setuserRoles] = React.useState([]);
	const [currentUser, setcurrentUser] = React.useState({});

	const [pending, setPending] = React.useState(false);
	const [success, setsuccess] = React.useState(false);
	const [error, seterror] = React.useState(false);
	const [errorMessage, seterrorMessage] = React.useState("");

	const [userNameValid, setuserNameValid] = React.useState(true);
	const [disableSave, setdisableSave] = React.useState(false);

	const { execute, result } = useFetchWithMsal({
		scopes: props.loginConfig.protectedResources.apiDocumentList.scopes.read,
	});

	const getData = () => {
		setPending(true);
		execute("GET", apiRoutes.users.getUser + `/` + userId)
			.then(json => {
				if (json != null) {
					if (json.user == null)
						json.user = { status: 1 };
					if (json.user.userRoleId == null)
						json.user.userRoleId = 0;
					json.roles.unshift({
						userRoleId: 0,
						roleName: props.localizations.SelectRole ?? "-- Select a User Role --"
					});
					if (json.user.phoneNumber == null)
						json.user.phoneNumber = "";
					setuserRoles(json.roles);
					setcurrentUser(json.user);
					setPending(false);
				}
			}).catch(err => {
				seterrorMessage(err);
				seterror(true);
			});
	}

	const validateUser = () => {
		setuserNameValid(Boolean(currentUser.userName));
		return Boolean(currentUser.userName);
	}

	const handleSubmission = () => {
		if (validateUser()) {
			setdisableSave(true);
			setPending(true);
			currentUser.phoneNumberWithCode = "";
			currentUser.firstName = "";
			currentUser.lastName = "";
			currentUser.externalUserId = "";

			if (currentUser.userType == null)
				currentUser.userType = usertypes.Tenent;

			currentUser.customerId = props.currentTenent.CustomerId;
			if (currentUser.userId == null)
				currentUser.userId = 0;

			execute("POST", apiRoutes.users.post, currentUser)
				.then(json => {
					if (json != null) {
						setdisableSave(false);
						if (json.status === 401)
							navigate("/");

						if (json) {
							setPending(false);
							setsuccess(true);
							setTimeout(function () { navigate("/manageUsers"); }, 1500);
						}
						else {
							seterrorMessage(props.localizations.UserNameNotUnique);
							seterror(true);
							setPending(false);
						}
					}
				})
				.catch(err => {
					seterrorMessage(err);
					seterror(true);
					setPending(false);
				});
		}
		else {
			seterrorMessage(props.localizations.NotAllFieldsValid);
			seterror(true);
		}
	};
	
	const [gotData, setgotData] = React.useState(false);
	React.useEffect(() => {
		if (gotData || result == null)
			return;
		setgotData(true);
	}, [execute]);

	React.useEffect(() => {
		if (gotData)
			getData();
	}, [gotData]);

	return (
		<Layout versionNumber={props.versionNumber} currentTenent={props.currentTenent} success={success} setSuccess={setsuccess} error={error} setError={seterror} errorMessage={errorMessage} localizations={props.localizations} changeLanguage={props.changeLanguage} languageValue={props.languageValue} >
			<div className="pageHeader">{props.localizations.ManageIaUser}</div>
			<br />

			<UserForm currentUser={currentUser} setcurrentUser={setcurrentUser} CustomerWizard={false}
				localizations={props.localizations} userNameValid={userNameValid} 
				setuserNameValid={setuserNameValid} />

			{pending && <LoadingPanel localizations={props.localizations} />}

			<br />
			<div className="buttonDiv">
				<SpheraButtonEx text={props.localizations.Cancel} onClick={() => navigate("/manageUsers")} />
				<SpheraButtonEx text={props.localizations.Save} primary onClick={handleSubmission} disabled={disableSave} />
			</div>
		</Layout>
	);
}
