import React from "react";
import { Toolbar, ToolbarSpacer } from "@progress/kendo-react-buttons";
import { SpheraButtonEx, SpheraButtonGroup } from "@sphera-cloud/component-ui-reactjs";

export const WidgetsToolbar = (props) => {

  const createNewWidget = (event) => {
    // props.setIsSiteFormValid(true);
     props.addWidgetHandler(event);
  };

  return (
    <Toolbar>
      <ToolbarSpacer />
      <SpheraButtonGroup>
        <SpheraButtonEx
          text={props.localizations.AddWidget}
          onClick={(event) => {createNewWidget(event);}}
        />
      
      </SpheraButtonGroup>
    </Toolbar>
  );
};
