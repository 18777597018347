import React from 'react';
import { Layout } from './Layout';
import { process } from "@progress/kendo-data-query";
import { UserListGrid } from './UserListGrid';
import useFetchWithMsal from '../hooks/useFetchWithMsal';
import { apiRoutes } from '../apiRoutes';
import { useNavigate } from "react-router-dom";
import { DateFormater } from "./DateFormater"

export function UserList(props) {
	const { adjustForTimezone } = DateFormater();
	let navigate = useNavigate();

	const [downloadGrid, setdownloadGrid] = React.useState(false);
	const [showColumnSelect, setshowColumnSelect] = React.useState(false);
	const [buttonsConfig, setbuttonsConfig] = React.useState([]);

	const [users, setusers] = React.useState([]);
	const [originalUsers, setoriginalUsers] = React.useState([]);
	const [dataState, setDataState] = React.useState({ skip: 0, take: 25 });
	const [pending, setPending] = React.useState(false);
	const [showAll, setshowAll] = React.useState(false);
	const [dataResult, setDataResult] = React.useState(
		process(users, dataState)
	);
	const dataStateChange = (event) => {
		setDataResult(process(users, event.dataState));
		setDataState(event.dataState);
	};

	const { execute, result } = useFetchWithMsal({
		scopes: props.loginConfig.protectedResources.apiDocumentList.scopes.read,
	});

	const handleShowAllUsersChange = () => {
		setshowAll(!showAll);

		if (showAll) {
			let filteredUsers = originalUsers.filter(x => x.hasIaPassword === "Yes");
			setusers(filteredUsers);
			setDataResult(process(filteredUsers, dataState));
		}
		else {
			setusers(originalUsers);
			setDataResult(process(originalUsers, dataState));
		}
	}

	const getData = () => {
		setPending(true);
		execute("GET", `${apiRoutes.users.get}?localeId=${props.languageValue.localeId}`)
			.then(json => {
				if (json != null) {
					let newusers = json.map(x => {
						if (x.timestampCreate != null) {
							x.timestampCreateDisplay = adjustForTimezone(x.timestampCreate.substring(0, x.timestampCreate.indexOf("+")), props.customer.TimezoneId);
							x.timestampCreate = new Date(adjustForTimezone(x.timestampCreate.substring(0, x.timestampCreate.indexOf("+")), props.customer.TimezoneId).toDateString());
						}
						return x;
					});
					let filteredUsers = newusers.filter(x => x.hasIaPassword === "Yes");


					setusers(filteredUsers);
					setoriginalUsers(newusers);
					setDataResult(process(filteredUsers, dataState));
					setPending(false);
				}
			})
			.catch((err) => {
				console.log(err);
			});
	}

	const [gotData, setgotData] = React.useState(false);
	React.useEffect(() => {
		if (gotData || result == null)
			return;
		if (props.currentTenent.CustomerId != null && !props.currentTenent.IsIAUserAdmin)
			navigate("/");
		setgotData(true);
	}, [execute]);

	React.useEffect(() => {
		if (gotData)
			getData();
	}, [gotData]);

	React.useEffect(() => {
		if (props.localizations.IncludeActive != null)
			setbuttonsConfig([
				{
					type: 'primary',
					text: props.localizations.AddUser,
					onClick: () => { navigate("/manageUser/0"); }
				},
				{
					type: 'secondary',
					icon: 'icon-ui-general-libraries',
					tooltip: props.localizations.Columns,
					position: "top",
					onClick: () => { setshowColumnSelect(true); }
				},
				{
					type: 'secondary',
					icon: 'icon-ui-general-download',
					tooltip: props.localizations.Download,
					position: "top",
					onClick: () => { setdownloadGrid(true); }
				}
			]);
	}, [props.localizations]);

	return (
		<Layout versionNumber={props.versionNumber} buttonsConfig={buttonsConfig} currentTenent={props.currentTenent} localizations={props.localizations} changeLanguage={props.changeLanguage} languageValue={props.languageValue} >
			<div>
				<UserListGrid customer={props.customer} dateFormatId={props.customer.dateFormatId} localizations={props.localizations}
					pending={pending} users={users} dataState={dataState} dataResult={dataResult} dataStateChange={dataStateChange}
					showColumnSelect={showColumnSelect} downloadGrid={downloadGrid} setdownloadGrid={setdownloadGrid} setshowColumnSelect={setshowColumnSelect} handleShowAllUsersChange={handleShowAllUsersChange} showAll={showAll} />
			</div>
		</Layout>
	);
}
