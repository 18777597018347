import * as Prism from 'prismjs';
import PropTypes from 'prop-types';
import { useEffect, useMemo, useRef } from 'react';
import './prism-languages';
import clsx from 'clsx';

function Highlight(props) {
    const { async, children, className, component: Wrapper } = props;
  
    const domNode = useRef(null);
  
    useEffect(() => {
      if (domNode.current) {
        Prism.highlightElement(domNode.current, async);
      }
    }, [children, async]);
  
    return useMemo(() => {
      const trimCode = () => {
        let sourceString = children;
  
        if (typeof sourceString === 'object' && sourceString.default) {
          sourceString = sourceString.default;
        }

        const sourceLines = sourceString.split('\n');
  
        if (!sourceLines[0].trim()) {
          sourceLines.shift();
        }
  
        if (!sourceLines[sourceLines.length - 1].trim()) {
          sourceLines.pop();
        }
  
        const indexOfFirstChar = sourceLines[0].search(/\S|$/);
  

        let sourceRaw = '';
  
        sourceLines.forEach((line, index) => {
          sourceRaw += line.substr(indexOfFirstChar, line.length);
  

          if (index !== sourceLines.length - 1) {
            sourceRaw = `${sourceRaw}\n`;
          }
        });
        return sourceRaw || '';
      };
  
      return (
        <>
          <Wrapper ref={domNode} className={clsx('border', className)}>
            {trimCode()}
          </Wrapper>
        </>
      );
    }, [children, className]);
  }
  
  Highlight.propTypes = {
    component: PropTypes.node,
  };
  Highlight.defaultProps = {
    component: 'code',
  };
  
export default Highlight;
  