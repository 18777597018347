import React from 'react';
import { NavMenu } from './components/MasterPage/NavMenu';
import { useMsal } from '@azure/msal-react';
import { EventType } from '@azure/msal-browser';
import { routes } from "./components/Routes";
import useFetchWithMsal from './hooks/useFetchWithMsal';
import { cookieHandler } from "./cookieHandler";
import { useNavigate, Routes, Route} from "react-router-dom";
import { SpheraNotFound, NavigationPanel, SpheraNoPermissions, EntitlementConfig } from "@sphera-cloud/component-ui-reactjs";
import menuItems from "./Data/menuItems.json";
import { apiRoutes } from './apiRoutes';

const handleHelpLinkClick = async () => {
	try {
		const helpConfigResponse = (await fetch(apiRoutes.helpconfig.get, { method: "GET" }));

		if (helpConfigResponse.ok) {
			const responseData = await helpConfigResponse.json();

			if (responseData.m_Uri) {
				window.open(responseData.m_Uri, '_blank');
			} else {
				console.error("m_Uri value not found in the response.");
			}
		} else {
			const result = await helpConfigResponse.text();
			console.error("Request failed with status: ", result);
		}
	} catch (error) {
		console.error("Error:", error);
	}
};

const Pages = (props) => {
	let navigate = useNavigate();
	const props404 = {
		errorMessage: 'Sorry, the page you requested was not found.', supportEmail: 'customercare@sphera.com', buttonText: 'Go Back', onBackButtonClick: (event) => { navigate(-1); }
	}
	const props401 = {
		errorMessage: "It looks like you do not have permission to access this page/app.",
		subMessage: "To get access, contact your system administrator.",
		redirectMessage: "While you're waiting, check out the complete list of SpheraCloud solutions ",
		redirectLink: "https://sphera.com/spheracloud/",
		redirectLinkText: "here.",
	}
	
	const [propsSphera401, setpropsSphera401] = React.useState({
		errorMessage: "Hello Spherion!",
		subMessage: "It looks like you do not have permission to access Document Storage. To access Document Storage, select another customer on the SpheraCloud landing page:",
		redirectMessage: "1) Go to Landing Page. _______________________________________ 2) Select your customer from the list. ________________________ 3) Use the App Switcher enter your desired solution. ________ ",
		redirectLink: "",
		redirectLinkText: "The SpheraCloud Landing Page",
	});

	const { setCookie, getCookie, eraseCookie } = cookieHandler();
	let languageJSON = getCookie("sphdsLanguage");
	let language = { "localeId": 1033, "languageCode": "en-US", "language": "English - United States", "localizationValue": [] };
	if (languageJSON != null)
		language = JSON.parse(languageJSON);

	const { instance } = useMsal();
	let activeAccount;
	if (instance) {
		activeAccount = instance.getActiveAccount();
	}

	const [customer, setcustomer] = React.useState({});
    const [localizations, setlocalizations] = React.useState([]);
	const [products, setproducts] = React.useState({});
	const [currentTenent, setcurrentTenent] = React.useState({});
	const [portalBaseDomain, setPortalBaseDomain] = React.useState('');
	const [versionNumber, setversionNumber] = React.useState("");

	const preferencesHandler = (e) => {
		setShowPreferencesModal(!showPreferencesModal);
	};

	const config = React.useMemo(() => {
		const entitlementConfig: EntitlementConfig = {
			baseConfig: {
				appSwitcherHeader: localizations.Subscribed ?? "Subscribed",
				activeSolutionFamily: "Corporate Sustainability",
				rootComponent: "#sch",
				baseUrl: "/",
				mobileMenu: false,
				footer: {
					url: "https://sphera.com",
					label: localizations.ExploreAdd ?? "ExploreAdd",
				},
			},
			user: {
				name: activeAccount && activeAccount.name ? activeAccount.name : 'Unknown',
				avatar: "",
				company: customer != null && customer.CustomerName != null ? customer.CustomerName : "",
				email: activeAccount && activeAccount.username ? activeAccount.username : 'Unknown',
				menuItems: [
					{
						"label": localizations.Preferences ?? "Preferences",
						onClick: () => preferencesHandler(),
						"id": "preferences"
					},
					{
						"label": localizations.Signout ?? "SignOut",
						onClick: () => navigate("/logout"),
						"id": "logout-url"
					}
				],
			},
			help: {
				links: [
					{
						label: localizations.GetHelp ?? "GetHelp",
						onClick: () => handleHelpLinkClick(),
					},
					{
						label: localizations.ThirdPartyNotice ?? "ThirdPartyNotice",
						onClick: () => navigate("/thirdpartynotice")
					}
				],
			},
			appSwitcher: [
				{
					"productLine": "Apps",
					"solutionFamilies": products
				}
			]
		};
		return entitlementConfig;
	}, [activeAccount, customer, localizations, navigate, products]);
	
	const [INIT_MENUS, setINIT_MENUS] = React.useState([]);
    const [languageValue, setlanguageValue] = React.useState(language);
	const [showPreferencesModal, setShowPreferencesModal] = React.useState(false);

	const { execute } = useFetchWithMsal({
		scopes: props.loginConfig.protectedResources.apiDocumentList.scopes.read,
	});

	const updateDefaultSite = (siteId) => {
		let updatedCustomer = customer;
		updatedCustomer.defaultSite = siteId;
		setcustomer(updatedCustomer);
	}

	const listToTree = (list) => {
		var map = {}, node, roots = [], i;

		for (i = 0; i < list.length; i += 1) {
			map[list[i].PhraseSectionTypeId] = i;
			list[i].children = [];
		}

		for (i = 0; i < list.length; i += 1) {
			node = list[i];
			if (node.PhraseSectionType.ParentPhraseSectionTypeId !== null) {
				list[map[node.PhraseSectionType.ParentPhraseSectionTypeId]].children.push(node);
			} else {
				roots.push(node);
			}
		}
		return roots;
	}

	const getData = (localeId) => {
		execute("GET", `${apiRoutes.users.requiredData}/${localeId}`)
			.then(json => {
					if (json != null) {
						if (json.status === 401 || json.CurrentTenant === null)
						{
							var emptyProducts = [];
							emptyProducts.push({
								group: "",
								url: "",
								iconColor: "",
								shortcut: "",
								modules: []
							});
							setproducts([emptyProducts]);
							navigate("/NoPermissions");
						}
						else
						{
							setcurrentTenent(json.CurrentTenant);

							let newLocalizations = {};
							for (let i = 0; i < json.localizations.length; i++) {
								newLocalizations[json.localizations[i].Key] = json.localizations[i].TextValue;
							}

							setlocalizations(newLocalizations);
							json.customer.PhraseSections = listToTree(json.PhraseSections.sort((a, b) => a.SortOrder - b.SortOrder));
							json.customer.PhraseSections.forEach(x => {
								x.title = x.PhraseSectionType.Name;
							});
							setcustomer(json.customer);

							let MENUS = JSON.parse(JSON.stringify(menuItems.MENUS));
							MENUS.forEach(item => {
								item.label = newLocalizations[item.label] || item.label;
							});

							if (!json.CurrentTenant.IsEditConfigUser) {
								MENUS.splice(7, 1);
							}
							if (!json.CurrentTenant.IsIAUserAdmin) {
								MENUS.splice(5, 1);
							}
							if (!json.CurrentTenant.IsInternalSCUserManagementAdmin) {
								MENUS.splice(4, 1);
							}
							if (!json.CurrentTenant.IsReviewHistoryUser) {
								MENUS.splice(3, 1);
							}
							if (!json.CurrentTenant.IsEditCustomerPortalUser) {
								MENUS.splice(2, 1);
							}
							setINIT_MENUS(MENUS);
							if (json.portalBaseDomain) {
								setPortalBaseDomain(json.portalBaseDomain);
							}
							let products = [];
							let containsDs = false;
							try {
								let entitlements = json.entitlement;
								for (let i = 0; i < entitlements.products.length; i++) {
									if (json.DsProductId === entitlements.products[i].productId)
										containsDs = true;

									products.push({
										group: entitlements.products[i].solutionName,
										url: entitlements.products[i].productUrl,
										iconColor: entitlements.products[i].iconColor,
										shortcut: entitlements.products[i].shortcut,
										modules: []
									});
								}
								setproducts(products);
							}
							catch (ex) {
								console.error(ex + " entitlement: " + json.entitlement);
							}

							if (json.CurrentTenant.CustomerStatus !== 1 || !containsDs) {
								propsSphera401.redirectLink = json.landingPageUrl;
								setpropsSphera401(propsSphera401);
								json.CurrentTenant.RequiresNavMenu = false;
								setcurrentTenent(json.CurrentTenant);
								navigate(json.CurrentTenant.IsSpheraUser ? "/SpheraNoPermissions" : "/NoPermissions");
							}
						}
						
					}
			})
			.catch(err => {
				console.log(err);
			});
	}

	const getLocalizations = (execute, localeId) => {
		execute("GET", `${apiRoutes.documents.localizations}?interfaceLocaleId=${localeId}`).then((response) => {
			if (response != null) {
				let newLocalizations = {};
				for (let i = 0; i < response.length; i++) {
					newLocalizations[response[i].key] = response[i].textValue;
				}

				setlocalizations(newLocalizations);

				let MENUS = JSON.parse(JSON.stringify(menuItems.MENUS));
				MENUS.forEach(item => {
					item.label = newLocalizations[item.label] || item.label;
				});

				setINIT_MENUS(MENUS);
			}
		});
	}

	const changeLanguage = (newLanguage) => {
		eraseCookie("sphdsLanguage");
		setCookie("sphdsLanguage", JSON.stringify(newLanguage), 1);
		setlanguageValue(newLanguage);
		getLocalizations(execute, newLanguage.localeId);
	};

	React.useEffect(() => {
		if (versionNumber === "") {
			fetch(apiRoutes.documents.version, { method: "GET" }).then((response) => {
				response.json().then(json => {
					setversionNumber(json);
				});
			});
		}

		if (window.location.href.indexOf("NoPermissions") === -1)
			getData(languageValue.localeId);

		const callbackId = instance.addEventCallback((event) => {
			if (event.eventType === EventType.LOGIN_FAILURE) {
				console.log("login failed");
			}
		});

		return () => {
			if (callbackId) {
				instance.removeEventCallback(callbackId);
			}
		};
	}, [execute]);

	React.useEffect(() => {
		const itemKey = "msal.interaction.status";
		if (sessionStorage.getItem(itemKey)) {
		  sessionStorage.removeItem(itemKey);
		}
	  }, []);
	  

    return (
		<main>
			<NavMenu menuSettings={config}
					 localizations={localizations}
					 changeLanguage={changeLanguage}
					 languageValue={languageValue}
					 showPreferencesModal={showPreferencesModal}
					 togglePreferences={preferencesHandler}
					 />
			{(currentTenent != null && currentTenent.RequiresNavMenu) ? (
					<NavigationPanel
						allMenus={INIT_MENUS}
						lazyLoading={false}
						treeBased={true}
						reload={true}
						navigate={navigate}>
							<Routes>
								{routes.map(
									(route, idx) =>
										<Route key={idx} path={route.path} element={<route.type loginConfig={props.loginConfig}
											customer={customer} setcustomer={setcustomer} localizations={localizations} languageValue={languageValue}
											changeLanguage={changeLanguage}
											updateDefaultSite={updateDefaultSite}
											currentTenent={currentTenent}
											versionNumber={versionNumber}
											portalBaseDomain={portalBaseDomain} />}
										/>
								)}
								<Route path='/NoPermissions' element={<SpheraNoPermissions {...props401} />} />
								<Route path='/SpheraNoPermissions' element={<SpheraNoPermissions {...propsSphera401} />} />
								<Route path='*' element={<SpheraNotFound {...props404} />} />
							</Routes>	
				</NavigationPanel>):
					(<div>
							<Routes>
								{routes.map(
									(route, idx) =>
										<Route key={idx} path={route.path} element={<route.type loginConfig={props.loginConfig}
											customer={customer} setcustomer={setcustomer} localizations={localizations} languageValue={languageValue}
											changeLanguage={changeLanguage}
											updateDefaultSite={updateDefaultSite}
											currentTenent={currentTenent}
											versionNumber={versionNumber}
											portalBaseDomain={portalBaseDomain} />}
										/>
									)}
								<Route path='/NoPermissions' element={<SpheraNoPermissions {...props401} />} />
								<Route path='/SpheraNoPermissions' element={<SpheraNoPermissions {...propsSphera401} />} />
								<Route path='*' element={<SpheraNotFound {...props404} />} />
							</Routes>
					</div>)
				}
		</main>
    );
};

export function App(props) {
	const { instance } = useMsal();
	let activeAccount;

	if (instance)
		activeAccount = instance.getActiveAccount();

	if (activeAccount == null || (activeAccount.idTokenClaims.exp * 1000) < Date.now()) {
		props.instance.loginRedirect(props.loginConfig.loginRequest).catch((error) => console.log(error));
		return '';
	}

	return (
		<>
			<Pages loginConfig={props.loginConfig} />
		</>
	);
}
